import {  Popover } from 'antd';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from "react-router-dom";
import List from '@mui/material/List';
import "./sidebar.css"
import { styled, useTheme } from '@mui/material/styles';


import 'antd/dist/antd.css';

const ListTag = styled(ListItemText,{})(
    ({ theme })=>({
      '& .MuiListItemText-root':{
        border: '1px solid red'
      }
    })
  )

const PopoverItem = ({singleList,handleDropDown,classes,activeDropDown,open,index,activePath ,isTablet})=>{
    return (
        <>

            <Popover trigger="hover" placement="rightTop" content={
            <> 
            {singleList.childs.length?

              <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              className={`list-item ${activePath===singleList.link?'active-item':'notActivate'}` }

              onClick={()=>handleDropDown(index)}
             >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 0,
                  justifyContent: 'center',
                }}
                className={`${!open && "mrs-0"} `}
              >
             <img src={singleList.icon} className={classes.iconImg+' icon mr-3  '+ `${activePath===singleList.link?' active-item':' notActivate'}`} />
              </ListItemIcon>
              <ListItemText primary={singleList.title} sx={{ opacity: 1 }} className={` text-gray-300 ml-4`}/>
                {(activeDropDown===index) ? <ExpandLess className="text-gray-500"/> : <ExpandMore className="grayyy"/>}
              </ListItemButton>
              :       
               <ListItem key={index}  disablePadding sx={{ display: 'block' }} className={`list-item ${activePath===singleList.link?'active-item':'notActivate'}` }>
              <Link  to={singleList.link} title={singleList.title} >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                        sx={{
                        minWidth: 0,
                        mr: 2,
                        justifyContent: 'center',
                        }}
                        className={`${!open && "mrs-0"}`}

                    >
                        <img src={singleList.icon} className={classes.iconImg+' icon mr-3' + `${activePath===singleList.link?' active-item':' notActivate'}`} />
                    </ListItemIcon>
                    <ListTag  className="text-gray-300" >{singleList.title}</ListTag>
                </ListItemButton>
              </Link>
               </ListItem>
            }
              {singleList.childs.length>0 && 
                <Collapse in={activeDropDown===index} timeout="auto" unmountOnExit className='horizontal-line' id="topbar">
                  {singleList.childs.map((singleChild)=>(
                    <List component="div" disablePadding className={`list-item v-line ${activePath===singleList.link?'active-dropdown':'not-active-dropdown'}` } >
                      <Link to={singleChild.link} title={singleChild.title} className="h-line">
                      <ListItemButton sx={{ pl: 8 }} >
                        <ListItemText primary={singleChild.title} className="dot"/>
                      </ListItemButton>
                      </Link>
                    </List>
                  ))}
                </Collapse>
              }
            </>
            }
          >  


            {singleList.childs.length  > 0 && isTablet ? 
            <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={()=>handleDropDown(index)} className={`list-item ${activePath===singleList.link?'active-item':'notActivate'}` } >

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                className={`list-item ${activePath===singleList.link?'active-item':'notActivate'}` }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                  className={`${!open && "mrs-0"}`}

                >
                  <img src={singleList.icon} className={classes.iconImg+' icon   '} />
                </ListItemIcon>
                <ListItemText primary={singleList.title} sx={{ opacity: open ? 1 : 0 }} />
                {open ? (activeDropDown===index) ? <ExpandLess /> : <ExpandMore /> :""}

              </ListItemButton>
            </ListItem>
            :
            <ListItem key={index} disablePadding sx={{ display: 'block' }} className={`list-item ${activePath===singleList.link?'active-item':'notActivate'}` }>
              <Link  to={singleList.link} title={singleList.title} >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                    className={`${!open && "mrs-0"}`}
                  >
                    <img src={singleList.icon} className={classes.iconImg+' icon '} />
                  </ListItemIcon>
                  <ListTag  className={`${!open && 'hidden'} text-gray-300`} >{singleList.title}</ListTag>

                </ListItemButton>
              </Link>
            </ListItem>
            }
            
            </Popover> 
              



        </>
    )
}

export default PopoverItem;