import React from 'react'
import signin from '../../layout/img/signin.png'
import {useNavigate} from "react-router-dom"

const Donepassword = () => {
    let navigate = useNavigate();

    document.title = "Admin Console : : Confirm Password"



  return (
    <>
    <section class="h-screen">
                <div class=" h-full w-full">
                    <div class="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
                        <div class=" md:w-6/12 mb-12 md:mb-0 w-full">
                            <img
                                src={signin}
                                class="w-full h-[100vh] md:block lg:block xl:block "
                                alt="Phone image"
                            />
                            
                        </div>
                        
                        <div class=" md:w-5/12 border-0 p-8 shadow-2xl sm:w-[80%] sm:m-auto lg:w-5/12">
                            <div className="flex justify-center">
                                <svg width="35" height="35" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="23" fill="#0075FC"/>
                                </svg>
                                <div className="w-100 text-center mb-6 font-bold text-2xl ml-2">Logo</div>
                            </div>
                            <div className="flex justify-center">
                                <svg width="80" height="80" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="68" cy="68" r="68" fill="#0085FF" fill-opacity="0.1"/>
                                <circle cx="68.7473" cy="67.2526" r="50.8132" fill="#0085FF" fill-opacity="0.1"/>
                                <path d="M88.1759 65.8829V68.014C88.173 73.0093 86.5555 77.8699 83.5645 81.8708C80.5735 85.8717 76.3694 88.7986 71.5791 90.215C66.7888 91.6313 61.6689 91.4612 56.9832 89.7301C52.2974 87.9989 48.2968 84.7995 45.578 80.6089C42.8591 76.4183 41.5677 71.4611 41.8964 66.4766C42.2251 61.4921 44.1562 56.7474 47.4018 52.9501C50.6473 49.1528 55.0334 46.5064 59.9059 45.4055C64.7784 44.3046 69.8763 44.8083 74.4391 46.8414" stroke="#0364BE" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M89.297 48.5723L65.0113 72.8823L57.7256 65.5966" stroke="#0364BE" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className="mb-10 font-bold text-2xl text-center">Your password reset successfully</div>
                            <div className='text-center flex-wrap'>
                                <button type=""  onClick={()=>{
                                                                            navigate("/setpassword", { replace: true })

                                }}
                                    class="mt-4 mr-4 inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-[175px]">Replace</button>
                                <button type="" onClick={()=>{
                                                                            navigate("/signin", { replace: true })

                                }}
                                    class="mt-4 mr-4 inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-[175px]">ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
  )
}

export default Donepassword