import React,{useState} from 'react'
import AddRolePermission from '../../components/AddRolePermission';
function UserAdd() {
  const [model, setmodel] = useState(false);

  return (
    <>
      <nav className="bg-white border-gray-200 py-2.5 rounded ">
        <div className="container flex flex-wrap justify-between items-center mx-auto margin-style">
          <a href="" className="flex items-center justify-between">
            <svg
              width="9"
              height="18"
              viewBox="0 0 9 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.7959 1.73977L1.81923 7.71643C1.1134 8.42227 1.1134 9.57727 1.81923 10.2831L7.7959 16.2598"
                stroke="#323232"
                strokewidth="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="self-center text-xl font-semibold whitespace-nowrap text-[#323232]  pl-3">
              Add User
            </span>
          </a>
        
        </div>
      </nav>
      <div className="border back-forword-btn rounded-2xl mt-4 margin-style">
        <div className="flex justify-between px-4 lg:px-4 py-4">
          <div className="font-semibold text-lg text-left  text-[#0075FC]">
            Basic Details
          </div>
          <div className="flex md:order-2 items-center justify-between">
            <span className=" text-sm font-medium text-[#0075FC]  pr-5">
              Active
            </span>
            <label
              for="checked-toggle"
              class="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                value=""
                id="checked-toggle"
                class="sr-only peer"
              
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-[#F5F2F2] after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <hr className="pb-4 border-t-[#EBEBEB]"></hr>
        <div className="flex justify-between pb-6 ">
          <div className="w-1/2  lg:px-4 px-4">
            <div className="flex items-center mt-1">
              <div className=" font-medium justify-start w-1/5 lg:text-sm text-xs text-[#383838]">
                Name
              </div>
              <input
                type="text"
                placeholder="A |"
                className="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex items-center mt-1">
              <div className=" font-medium w-1/5 text-[#383838] lg:text-sm text-xs">
                Department
              </div>
              <input
                type="text"
                placeholder="Enter Department"
                className="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex items-center mt-1">
              <div className=" font-medium w-1/5 text-[#383838] lg:text-sm text-xs">
                Phone No{" "}
              </div>
              <input
                type="number"
                placeholder="99458 58962"
                className="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex items-center mt-1">
              <div className=" font-medium w-1/5 text-[#383838] lg:text-sm text-xs">
                Password
              </div>
              <input
                type="password"
                placeholder="*********"
                className="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
          <div className="w-1/2  lg:px-4 px-4">
            <div className="flex items-center mt-1">
              <div className=" font-medium justify-start w-1/5 lg:text-sm text-xs text-[#383838]">
                Email
              </div>
              <input
                type="text"
                placeholder="Write here"
                className="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex items-center mt-1">
              <div className=" font-medium w-1/5 text-[#383838] lg:text-sm text-xs">
                Designation
              </div>
              <input
                type="text"
                placeholder="Type here"
                className="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex items-center mt-1">
              <div className=" font-medium w-1/5 text-[#383838] lg:text-sm text-xs">
                Emp Code{" "}
              </div>
              <input
                type="number"
                placeholder=""
                className="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex items-center mt-1">
              <div className=" font-medium w-1/5 text-[#383838] lg:text-sm text-xs">
                Last Log
              </div>
              <input
                type="password"
                placeholder=""
                className="bg-[#F5F2F2]  text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between px-4 lg:px-4 py-4">
          <div className="font-semibold text-lg text-left  text-[#0075FC]">
            Role
          </div>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <hr className="pb-4 border-t-[#EBEBEB]"></hr>
        <div className="flex justify-between pb-6 ">
          <div className="w-1/2  lg:px-4 px-4">
            <div className="flex items-center mt-1">
              <div className=" font-medium justify-start w-1/5 lg:text-sm text-xs text-[#383838]">
                Role Type
              </div>
              <select
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              >
                <option selected>Manager</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
              </select>
            </div>
          </div>
          <div className="w-1/2  lg:px-4 px-4">
            <div className="flex items-center mt-2">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14H21"
                  stroke="#0075FC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14 21V7"
                  stroke="#0075FC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#0075FC] cursor-pointer" onClick={() => setmodel(true)}>Add Role</div>

              <AddRolePermission open={model} setmodel={setmodel} />

            </div>
          </div>
        </div>

        <div className="flex justify-between px-4 lg:px-4 py-4">
          <div className="font-semibold text-lg text-left  text-[#0075FC]">
            Add Right
          </div>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <hr className="pb-4 border-t-[#EBEBEB]"></hr>
        <div className="flex  pb-6 ">
          <div className="w-1/6  lg:px-4 px-4">
            <div className="flex items-center mt-1">
              <input
               
                id="inline-checkbox"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-[#F5F2F2] focus:ring-blue-500 focus:ring-2 "
              />
              <label
                for="inline-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                View
              </label>
            </div>
          </div>
          <div className="w-1/6  lg:px-4 px-4">
            <div className="flex items-center mt-2">
              <input
             
                id="inline-checkbox"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-[#F5F2F2] focus:ring-blue-500 focus:ring-2 "
              />
              <label
                for="inline-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                Add
              </label>
            </div>
          </div>
          <div className="w-1/6  lg:px-4 px-4">
            <div className="flex items-center mt-2">
              <input
                checked
                id="inline-checkbox"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-[#F5F2F2] focus:ring-blue-500 focus:ring-2 "
              />
              <label
                for="inline-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                Edit
              </label>
            </div>
          </div>
          <div className="w-1/6  lg:px-4 px-4">
            <div className="flex items-center mt-2">
              <input
             
                id="inline-checkbox"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-[#F5F2F2] focus:ring-blue-500 focus:ring-2 "
              />
              <label
                for="inline-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                Delete
              </label>
            </div>
          </div>
          <div className="w-1/6  lg:px-4 px-4">
            <div className="flex items-center mt-2">
              <input
             
                id="inline-checkbox"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-[#F5F2F2] focus:ring-blue-500 focus:ring-2 "
              />
              <label
                for="inline-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                Print
              </label>
            </div>
          </div>
          <div className="w-1/6  lg:px-4 px-4">
            <div className="flex items-center mt-2">
              <input
              
                id="inline-checkbox"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-[#F5F2F2] focus:ring-blue-500 focus:ring-2 "
              />
              <label
                for="inline-checkbox"
                class="ml-2 text-sm font-medium text-gray-900"
              >
                Export
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-center px-5 lg:px-5 py-4 mt-12">
          <button
            type="button"
            class="text-[#0075FC] bg-[#ffffff] border-2 border-[#0075FC] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 "
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-[#ffffff] bg-[#0075FC] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default UserAdd;
