import React,{useState,useEffect} from "react";
import {useNavigate} from "react-router-dom"
import signin from '../../layout/img/signin.png'

export default function Signin() {
    let navigate = useNavigate();
    document.title = "Admin Console : : Reset Password"

    return (
        <>
            <section class="h-screen">
                <div class=" h-full w-full">
                    <div class="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
                        <div class=" md:w-6/12 mb-12 md:mb-0 w-full">
                            <img
                                src={signin}
                                class="w-full h-[100vh] md:block lg:block xl:block "
                                alt="Phone image"
                            />
                            
                        </div>
                        
                        <div class=" md:w-5/12 border-0 p-8 shadow-2xl sm:w-[80%] sm:m-auto lg:w-5/12">
                            <div className="flex justify-center">
                                <svg width="35" height="35" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="23" fill="#0075FC"/>
                                </svg>
                                <div className="w-100 text-center mb-6 font-bold text-2xl ml-2">Logo</div>
                            </div>
                            <div className="mb-6 font-bold text-2xl"><span className="border-b-4 border-blue-600 pb-1">Reset</span>&nbsp;Password</div>
                            <div>
                                <div>
                                    <p className="pr-12 pb-6">We are sending you this email as you have requested a password reset. Click on the reset button down below to create a new password.</p>
                                </div>
                                <button
                                    type=""
                                    class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-[125px]"
                                    onClick={()=>{
                                        navigate("/setpassword", { replace: true })
                                    }}
                                >Reset</button>
                                <div className="mt-4 text-[#D3D3D3] mb-12">If you did not intiante this request, please get back to us immediately at User@gmail.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

