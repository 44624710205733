import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function AddMoreBtn({ list, handleClick, title,checkedList }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
      >
        <span
          className={
            "text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5"
          }
        >
          {title}
        </span>
      </Button>
      <Menu
        id="basic-menu"
        aria-labelledby="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",top:500

        }}
      >
        {list.map((li) => (
          <MenuItem key={li.val}>
            <div className="flex py-2 rounded hover:bg-[#e5f0fe] w-full " >
             <label
                for={"default-toggle-" + li.val}
                className="relative items-center w-full cursor-pointer flex flex-row-reverse justify-between"
              >
                 <input
                  type="checkbox"
                  value={li.val}
                  id={"default-toggle-" + li.val}
                  className="sr-only peer"
                  checked={checkedList.includes(li.val)}
                  onClick={(e) => {
                    handleClick(li.val);
                    e.stopPropagation();
                  }}
                />
                <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:right-[17px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-900 ">
                  {li.title}
                </span>
              </label>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default AddMoreBtn;
