const DIcon = require("./img/home-2.png");
const AdminIcon = require("./img/profile-2user.png");
const FolderIcon = require("./img/folder-cloud.png");
const Settings = require("./img/setting-2.png");
const taskIcons = require("./img/task-square.png");
const LoginIcon = require("./img/command.png");
const chart = require("./img/chart.png");

const SidebarData = [
  { title: "Dashboard", link: "/dashboard", icon: DIcon, childs: [] },
  // {title:'Admin Console',link:'/admin-console',icon:AdminIcon,
  // childs:[
  //     {title:'User Manangement',link:'/add-user',icon:AdminIcon},
  //     {title:'FTP Management',link:'/admin-console',icon:AdminIcon},
  //     {title:'Exchange & Segment',link:'/admin-console',icon:AdminIcon}
  // ]
  // },
  // {title:'Data Upload',link:'/data-uploads',icon:FolderIcon,childs:[]},
  {
    title: "Obituary",
    link: "/obituary-details",
    icon: FolderIcon,
    childs: [],
  },
  { title: "User", link: "/user", icon: AdminIcon, childs: [] },
  { title: "Enquiry", link: "/enquiry", icon: taskIcons, childs: [] },
  // {title:'Reports',link:'/reports',icon:taskIcons,childs:[]},
  // {title:'Logic Definition',link:'/logic-defination',icon:LoginIcon,childs:[]},
  // {title:'Reporting To Exchange',link:'/report-to-exchange',icon:chart,childs:[]},
  // {title:'Reporting to FIU',link:'/report-to-fiu',icon:taskIcons,childs:[]},
  // {title:'Client Master',link:'/client-master',icon:taskIcons,childs:[]},
  // {title:'Setting',link:'/settings',icon:Settings,childs:[]},
];

export default SidebarData;
