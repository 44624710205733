import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import signin from "../../layout/img/signin.png";
import LogoImg from "../../img/honor_logo.png";
import { CallPOSTAPI } from "../../helper/Api";
import { toast } from "react-toastify";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [eye, seteye] = useState(false);
  const [password, setpassword] = useState("");
  const [check, setcheck] = useState({});
  const [sign, setSign] = useState(false);
  let navigate = useNavigate();
  document.title = "Admin Console : : Sign in";
  console.log({ email });
  useEffect(() => {
    if (password.length <= 8) {
      setcheck({ ...check, otp: "" });
    }
  }, [password]);

  const validate = (e) => {
    let Obj = { ...check };
    Obj[e.target.name] = e.target.value;
    setcheck({ ...Obj });
  };
  const [msg, setMsg] = useState("");
  // const handleSubmit = async ()=>{
  //     // e.preventDefault();
  //     console.log({check})
  //     if(check.username==='admin' && check.password==='admin@123' ){
  //         navigate('/dashboard')
  //     }else{
  //         setMsg('Invalid Username Password')
  //     }
  // }
  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Perform login logic here
      // For simplicity, let's just log the email and password
      const send_obj = {
        email: email,
        password: password,
      };
      console.log({ send_obj });
      let res = await CallPOSTAPI("api/admin-login", send_obj);
      if (res?.data?.status) {
        let token = res?.data?.token;
        localStorage.setItem("obituary_token", token);
        toast.success("Login Successful");
        navigate("/dashboard");
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  return (
    <>
      <section class="h-screen">
        <div class=" h-full w-full">
          <div class="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
            <div class=" md:w-6/12 mb-12 md:mb-0 w-full">
              <img
                src={signin}
                class="w-full h-[100vh] md:block lg:block xl:block "
                alt="Phone image"
              />
            </div>

            <div class=" md:w-5/12 border-0 p-8 shadow-2xl sm:w-[80%] sm:m-auto lg:w-5/12">
              <div className="flex justify-center">
                <img src={LogoImg} width="180px" />
              </div>
              <div className="mb-6 font-bold text-2xl">
                <span className="border-b-4 border-blue-600 pb-1">Sign</span>
                &nbsp;in
              </div>
              <div>
                <form className="" onSubmit={handleSubmit} method="POST">
                  <div class="mb-6">
                    <div className="my-2 font-bold">Email</div>

                    {/* === */}
                    <input
                      type="email"
                      class=" text-[1rem] form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Your User Id"
                      id="email"
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {/* {!check.userid && sign && (
                    <div className="text-[0.75rem] text-[#FF0000]">
                      *Please enter email
                    </div>
                  )} */}
                  </div>

                  <div class="mb-6">
                    <div className="my-2 font-bold">Password</div>
                    <input
                      type="password"
                      class=" text-[1rem] form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Your Password"
                      name="password"
                      onChange={(event) => setpassword(event.target.value)}
                    />
                    {/* {!check.password && sign && (
                    <div className="text-[0.75rem] text-[#FF0000]">
                      *Please enter Password
                    </div>
                  )} */}
                  </div>
                </form>
                {/* <div class="mb-6 relative">
                                    <div className='my-2 font-bold'>Password</div>
                                    <input
                                        value={password}
                                        onChange={(e)=>{
                                           setpassword(e.target.value);validate(e)
                                        }}
                                        type={ eye  ? 'text':'password'}
                                        class=" text-[1rem]  form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Enter Your Password"
                                        name="password"
                                    />
                                    {!check.password&&sign&&<div className='text-[0.75rem] text-[#FF0000]'>*Please enter password</div>}

                                    <svg onClick={()=>{
                                        seteye(!eye)
                                    }} class="absolute top-[47px] right-[13px] bi bi-eye"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={!eye ? 'blue':'grey'}  viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                <div href="#!" class="w-100 text-right text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out" onClick={()=>{
                                                                            navigate("/resetpassword", { replace: true })

                                }}>Forgot password?</div>

                                </div> */}
                {/* {password.length >= 8&& <div class="mb-6">
                                    <div className='mb-2 font-bold'>Enter Your Otp</div>
                                    <input
                                        type="number"
                                        class=" text-[1rem]  form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Enter Otp"
                                        name="otp"
                                        onChange={(e)=>{validate(e)}}
                                    />
                                    {!check.otp&&sign&&<div className='text-[0.75rem] text-[#FF0000]'>*Please enter otp</div>}
                                    <div class="flex justify-between w-100 text-right ">
                                        <div className="text-[#808080]">Time Remaining 0:38</div>
                                        <div className="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out">Resend Otp</div>
                                    </div>
                                </div>} */}
                {msg && <div className="text-red-400 ">{msg}</div>}
                <button
                  type="submit"
                  class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-[125px]"
                  onClick={handleSubmit}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
