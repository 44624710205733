import React,{useEffect,useState} from 'react';
import {GlobalCallGet} from '../../service/Services';
import { useLocation } from "react-router-dom";


// function Aml({ClientData,Edit}){
function Aml({id}){

  const location  = useLocation()
  const [clientData,setclientData] = useState([]);
  const [Edit, setEdit] = useState(true)


  const fetchMethod = async ()=>{
    let res = await GlobalCallGet('api/Masters/ClientCorporateExposureDetails?ClientId='+id);
    if(res.status){
      setclientData(res.result)
    }
  }
  useEffect(()=>{

    if(location.search.includes("true")) {
      setEdit(false) ;
    } else{
      setEdit(true) ;

    }
  },[location.search.includes("edit")])

  useEffect(()=>{
    fetchMethod();
  },[])
  return (
    <>
     <div className="border back-forword-btn rounded-md mt-4">
     <div className="md:flex lg:flex xl:flex px-2 lg:px-2 py-4">
     <div class="flex items-center mr-4">
        <input disabled={Edit}  defaultValue={clientData[0]?.amlProfile}
         id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"/>
        <label for="inline-checkbox" class="ml-2 text-sm font-medium text-[#3D3D3D] dark:text-gray-300">AML Profile</label>
    </div>
    <div class="flex items-center mr-7">
        <input disabled={Edit}  defaultValue={clientData[0]?.alertExclusion}
        id="inline-2-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"/>
        <label for="inline-2-checkbox" class="ml-2 text-sm font-medium text-[#3D3D3D] dark:text-gray-300">Alert Exclusion</label>
    </div>
     </div>
     </div>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z" fill="#0075FC" />
            </svg>
            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
            Corporate exposure details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex justify-between pb-6 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
          <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
              Company Name
              </div>
              <input disabled={Edit}
                
                type="text"
                placeholder="xyz"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              Person Name
              </div>
              <input disabled={Edit}
              
                type="text"
                placeholder="Pooja"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>           
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
          <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              Designation
              </div>
              <input disabled={Edit}
      
                type="text"
                placeholder="hbvjh"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div> 
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              Relation
              </div>
              <input disabled={Edit}
              
                type="text"
                placeholder="gyug"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z" fill="#0075FC" />
            </svg>
            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
            PEP
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex justify-between pb-6 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
          <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              PEP Flag
              </div>
              <select  disabled={Edit}
                id="countries"
                class="bg-gray-50 border border-[#E8E2E2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5"
              >
                <option selected value="" >Select status</option>
                <option value="F1">F1</option>
                <option value="F2">F2</option>
                <option value="F3">F3</option>
                <option value="F4">F4</option>
              </select>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              PEP Name
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={clientData[0]?.pepName}
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
              Relation period Start
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={clientData[0]?.relationPeriod}
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>   
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
          <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              PEP PAN
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={clientData[0]?.peppan}
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              Relation Type
              </div>
              <input disabled={Edit}  defaultValue={clientData[0]?.relationType}
                type="text"
               
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 xl:w-2/5 lg:w-2/5 text-[#383838] lg:text-sm text-xs">
              Relation period End
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={clientData[0]?.relationPeriod}
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
           
          </div>
        </div>
      </div>
      {!Edit&&<div className="flex justify-center px-5 lg:px-5 py-4 mt-12">
          <button
            type="button"
            class="text-[#0075FC] bg-[#ffffff] border-2 border-[#0075FC] hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-[#ffffff] bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Submit
          </button>
        </div>}
    </>
  )
}

export default Aml