import React from "react";
import AlertTable from "./AlertTable";

function Alerts() {
  return (
    <>
      <div className="border rounded-md bg-[#FFFFFF] px-2 py-2">
        <div className="flex  justify-between items-center">
          <div className='items-center ml-2 py-2 px-4 w-2/5 sm:w-full  '>
            <div class="input-group relative  items-stretch w-[250px] rounded">
              <input
                type="search"
                class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
            </div>
          </div>
          <div className='items-center w-3/5 px-4 flex xs:w-full justify-around'>
           <div className="flex items-center" >
           <div for="countries" class="block mb-2 text-sm font-medium text-[#0075FC]">Alert Name</div>
            <select id="countries" class="form-select appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ">
              <option selected>select status</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select>
           </div>
            <div className=" ">
              <div className="flex space-x-2 ">
                <button
                  type="button"
                  className="text-[#0075FC] border border-[#0075FC]  inline-block px-6 py-2.5   font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg hover:text-white focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
               False Positive
                </button>
                </div>
              </div>
              <div className=" ">
              <div className="flex space-x-2 ">
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Add-More
                </button>
              </div>
            </div>
          </div>
        </div>
        <AlertTable/>
      </div>
    </>
  );
}

export default Alerts;
