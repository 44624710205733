import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spin } from "antd";

export function AuthCheck(props) {
  const navigate = useNavigate();
  const [authLoading, setAuthLoading] = useState(true);
  //   const { isLoggedIn } = useSelector((state) => state?.user);

  useEffect(() => {
    if (localStorage.getItem("obituary_token")) {
      setAuthLoading(false);
    } else {
      navigate("/");
      setAuthLoading(false);
    }
  }, [navigate]);

  return (
    <>
      {authLoading ? (
        <div className="h-screen flex justify-center items-center">
          <Spin size="large" className="">
            <h1 className="mt-20 text-blue-900">Loading...</h1>
            {/* <div className="content" /> */}
          </Spin>
        </div>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
}
