import React, { useEffect, useState } from 'react'
import DpDetails from './DpDetails';
import { clientBankDetails } from '../../service/Services';
import {  useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";





function BankDetail({ClientData,id}) {
  const [bankDetails,setBankdetails]=useState("")
  const [Edit, setEdit] = useState(true)
  const location  = useLocation()

  useEffect(()=>{
    setBankdetails(ClientData)
  },[])

  useEffect(()=>{

    if(location.search.includes("true")) {
      setEdit(false) ;
    } else{
      setEdit(true) ;

    }
  },[location.search.includes("edit")])

  useEffect(()=>{
    id && fetchClientBankDetails(id);
  },[id])

  const fetchClientBankDetails = async (id) => {
    const bank = await clientBankDetails(id)
    setBankdetails(bank)
  }

  return (
    <>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z" fill="#0075FC" />
            </svg>
            <span className="self-center text-xl font-bold whitespace-nowrap text-[#0075FC]  pl-3">
            Bank and DP Details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex justify-between pb-6 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
            Bank Name
              </div>
              <input disabled={Edit} defaultValue={bankDetails?.cm_bankname}
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Account Number
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={bankDetails?.cm_bankactno}
                placeholder="1234 2345 34555 "
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              MICR
              </div>
              <input disabled={Edit} 
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                1st Holder Name
              </div>
              <input disabled={Edit} defaultValue={bankDetails?.bankDetails?.[0]?._FirstHolderName}
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
           
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Branch
              </div>
              <input disabled={Edit} defaultValue={bankDetails?.bankDetails?.[0]?.cm_bankbranch}
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Account Type
              </div>
              <input disabled={Edit} defaultValue={bankDetails?.cm_bankacttype}
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              IFSC Code
              </div>
              <input disabled={Edit} 
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              1st Holder PAN
              </div>
              <input disabled={Edit} defaultValue={bankDetails?.bankDetails?.[0]?._FirstHolderPAN}
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <DpDetails ClientData={ClientData} />
      </div>
      {!Edit&&<div className="flex justify-center px-5 lg:px-5 py-4 mt-12">
          <button
            type="button"
            class="text-[#0075FC] bg-[#ffffff] border-2 border-[#0075FC] hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-[#ffffff] bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Submit
          </button>
        </div>}
    </>
  )
}

export default BankDetail