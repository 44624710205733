import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_API } from "../../helper/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadImg from "../../components/modals/UploadImg";

const ObituaryCreate = () => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dateOfDeathFilled, setDateOfDeathFilled] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [videoArray, setVideoArray] = useState(null);
  const [finalVideo, setFinalVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [finalThumbnail, setFinalThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    dateOfDeath: "",
    email: "",
    age: "",
    cityOfResidence: "",
    stateOfResidence: "",
    deathCareProvider: "",
    deathCareProviderCity: "",
    deathCareProviderCountry: "",
    deathCareProviderState: "",
    relationshipToDeceased: "",
    typeOfFuneralArrangement: "",
    add_img: [],
    add_video: [],
    thumbnail: "",
    obituaryText: "",
  });
  const [currentDate] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const newValue = e.target.type === "file" ? [...files] : value;
    const isDateOfBirth = name === "dateOfBirth";
    const isDateOfDeath = name === "dateOfDeath";
    const isAgeField = name === "age";
    const isValidAge = isAgeField
      ? value === "" || (/^\d+$/.test(value) && Number(value) >= 1)
      : true;

    if (isValidAge) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        ...(isDateOfBirth && { dateOfDeath: "" }),
      }));
    }
    if (isDateOfDeath && !dateOfDeathFilled) {
      setDateOfDeathFilled(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    (async () => {
      try {
        const apiUrl = BASE_API + "api/admin-obituary";
        setLoading(true);
        const newFormData = new FormData();
        newFormData.append("add_img", imageArray);
        newFormData.append("add_video", videoArray);
        newFormData.append("thumbnail", finalThumbnail);
        newFormData.append("firstName", formData.firstName);
        newFormData.append("middleName", formData.middleName);
        newFormData.append("lastName", formData.lastName);
        newFormData.append("email", formData.email);
        newFormData.append("dateOfBirth", formData.dateOfBirth);
        newFormData.append("dateOfDeath", formData.dateOfDeath);
        newFormData.append("age", formData.age);
        newFormData.append("cityOfResidence", formData.cityOfResidence);
        newFormData.append("stateOfResidence", formData.stateOfResidence);
        newFormData.append("deathCareProvider", formData.deathCareProvider);
        newFormData.append(
          "deathCareProviderCity",
          formData.deathCareProviderCity
        );
        newFormData.append(
          "deathCareProviderCountry",
          formData.deathCareProviderCountry
        );
        newFormData.append(
          "deathCareProviderState",
          formData.deathCareProviderState
        );
        newFormData.append(
          "relationshipToDeceased",
          formData.relationshipToDeceased
        );
        newFormData.append(
          "typeOfFuneralArrangement",
          formData.typeOfFuneralArrangement
        );
        newFormData.append("obituaryText", formData.obituaryText);

        const response = await axios.post(apiUrl, newFormData);

        if (response.data.status) {
          navigate("/obituary-details");
        } else {
          console.log("some error occured.");
        }
        setLoading(false);

        console.log("Form submitted:", response.data);
      } catch (error) {
        setLoading(false);
        console.error("Error submitting form:", error.message);
      }
    })();
  };

  const handleImageArray = (e) => {
    let file = e.target.files[0];
    setImageArray((prev) => [...prev, file]);
  };

  const handleVideoArray = (e) => {
    let file = e.target.files[0];
    // setVideoArray((prev) => [...prev, file]);
    if (file) {
      const allowedTypes = ["video/mp4", "video/webm"];
      if (allowedTypes.includes(file.type)) {
        console.log("File type is supported");
        setVideoArray(file);
      } else {
        e.target.value = "";
        console.log("File type not  supported");
        toast.error(" Please select a  MP4 video ");
        return; // Add this to prevent setting the state when the file type is unsupported
      }
    }
  };

  const handleThumbnail = (e) => {
    let file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        console.log("File type is supported");
        setThumbnail(file);
        setUploadModal(true);
      } else {
        // Clear the input field
        e.target.value = "";
        toast.error("Unsupported file type. Please select a video file.", {
          position: toast.POSITION.TOP_CENTER,
        });
        return; // Add this to prevent setting the state when the file type is unsupported
      }
    }
  };

  console.log("final thumbnail", finalThumbnail);
  return (
    <>
      <UploadImg
        isModalOpen={uploadModal}
        setIsModalOpen={setUploadModal}
        thumbnail={thumbnail}
        setFinalThumbnail={setFinalThumbnail}
      />
      <nav className="bg-white border-gray-200 py-2.5 rounded ">
        <div className="container flex flex-wrap justify-between items-center mx-auto margin-style">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="flex items-center justify-between"
          >
            <svg
              width="9"
              height="18"
              viewBox="0 0 9 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.7959 1.73977L1.81923 7.71643C1.1134 8.42227 1.1134 9.57727 1.81923 10.2831L7.7959 16.2598"
                stroke="#323232"
                strokewidth="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* <span className="self-center text-xl font-semibold whitespace-nowrap text-[#323232]  pl-3">
                  Add User
                </span> */}
          </button>
        </div>
      </nav>
      <div className="container mx-auto mt-8 mb-4">
        <form onSubmit={handleSubmit}>
          <div className="flex gap-4 w-100 mb-4">
            {/* First Name */}
            <div className="w-[33%]">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
            </div>

            {/* Middle Name */}
            <div className="w-[33%]">
              <label
                htmlFor="middleName"
                className="block text-sm font-medium text-gray-700"
              >
                Middle Name
              </label>
              <input
                type="text"
                id="middleName"
                name="middleName"
                value={formData.middleName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>

            {/* Last Name */}
            <div className="w-[33%]">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
            </div>
          </div>
          {/* date of birth ki row hai */}
          <div className="flex gap-4 w-full mb-4">
            <div className="w-[50%]">
              <label
                htmlFor="dateOfBirth"
                className="block text-sm font-medium text-gray-700"
              >
                Date of Birth
              </label>
              <input
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                max={currentDate}
              />
            </div>
            <div className="w-[50%]">
              <label
                htmlFor="dateOfDeath"
                className="block text-sm font-medium text-gray-700"
              >
                Date of Death
              </label>
              <input
                type="date"
                id="dateOfDeath"
                name="dateOfDeath"
                value={formData.dateOfDeath}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                min={formData.dateOfBirth}
                max={currentDate}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full mb-4">
            <div className="w-[50%]">
              <label
                htmlFor="cityOfResidence"
                className="block text-sm font-medium text-gray-700"
              >
                Last City of Residence
              </label>
              <input
                type="text"
                id="cityOfResidence"
                name="cityOfResidence"
                value={formData.cityOfResidence}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
            </div>
            <div className="w-[50%]">
              <label
                htmlFor="stateOfResidence"
                className="block text-sm font-medium text-gray-700"
              >
                Last State of Residence
              </label>
              <input
                type="text"
                id="stateOfResidence"
                name="stateOfResidence"
                value={formData.stateOfResidence}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
            </div>
          </div>

          <div className="flex gap-4 w-full mb-4">
            <div className="w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
            </div>
          </div>
          {/* Textarea for Obituary Text */}
          <div className="mt-4 mb-4">
            <label
              htmlFor="obituaryText"
              className="block text-sm font-medium text-gray-700"
            >
              Obituary Text
            </label>
            <textarea
              id="obituaryText"
              name="obituaryText"
              value={formData.obituaryText}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full h-40"
            />
          </div>

          {/* <div className="flex gap-4 w-full mb-4">
          <div className="w-full">
            <label
              htmlFor="add_img"
              className="block text-sm font-medium text-gray-700"
            >
              Upload Photo
            </label>
            <input
              type="file"
              id="add_img"
              name="add_img"
              onChange={handleImageArray}
              accept="image/*" // Restrict file types to images
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div> */}

          <div className="flex gap-4 w-full mb-4">
            {/* Upload Video */}
            <div className="w-[50%]">
              <label
                htmlFor="add_video"
                className="block text-sm font-medium text-gray-700"
              >
                Upload Video
              </label>
              <input
                type="file"
                id="add_video"
                name="add_video"
                accept="video/MP4"
                onChange={(e) => handleVideoArray(e)}
                // onChange={handleVideoArray}
                // accept="image/*" // Restrict file types to images
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
              {videoArray && (
                <div className="w-full mt-2">
                  <video
                    style={{
                      width: "50%",
                      objectFit: "cover",
                      margin: "auto",
                    }}
                    src={URL.createObjectURL(videoArray)}
                    controls
                  />
                </div>
              )}
            </div>

            {/* Upload Thumbnails */}
            <div className="w-[50%]">
              <label
                htmlFor="thumbnail"
                className="block text-sm font-medium text-gray-700"
              >
                Upload Thumbnails
              </label>
              <input
                type="file"
                id="thumbnail"
                name="thumbnail"
                onChange={(e) => {
                  handleThumbnail(e);
                }}
                accept="image/*" // Restrict file types to images
                // Allow multiple file selection
                required
                className="mt-1 p-2 border rounded-md w-full"
              />
              {finalThumbnail && (
                <div className="w-full mt-2">
                  <img
                    style={{
                      width: "50%",
                      objectFit: "cover",
                      margin: "auto",
                    }}
                    src={URL.createObjectURL(finalThumbnail)}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>

          {/* <div className="flex gap-4 w-full mb-4">
          <div className="w-full">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div> */}

          <div className="flex gap-4 w-100 mb-4">
            {/* First Name */}
            <div className="w-[33%]">
              <label
                htmlFor="deathCareProvider"
                className="block text-sm font-medium text-gray-700"
              >
                Death Care Provider
              </label>
              <input
                type="text"
                id="deathCareProvider"
                name="deathCareProvider"
                value={formData.deathCareProvider}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>

            {/* Middle Name */}
            <div className="w-[33%]">
              <label
                htmlFor="deathCareProviderCity"
                className="block text-sm font-medium text-gray-700"
              >
                Death Care Provider City
              </label>
              <input
                type="text"
                id="deathCareProviderCity"
                name="deathCareProviderCity"
                value={formData.deathCareProviderCity}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>

            {/* Last Name */}
            <div className="w-[33%]">
              <label
                htmlFor="age"
                className="block text-sm font-medium text-gray-700"
              >
                Age
              </label>
              <input
                type="number"
                id="age"
                name="age"
                value={formData.age}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>
          </div>

          <div className="flex gap-4 w-full mb-4">
            <div className="w-[50%]">
              <label
                htmlFor="deathCareProviderState"
                className="block text-sm font-medium text-gray-700"
              >
                Death Care Provider State
              </label>
              <input
                type="text"
                id="deathCareProviderState"
                name="deathCareProviderState"
                value={formData.deathCareProviderState}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>
            <div className="w-[50%]">
              <label
                htmlFor="deathCareProviderCountry"
                className="block text-sm font-medium text-gray-700"
              >
                Death Care Provider Country
              </label>
              <input
                type="text"
                id="deathCareProviderCountry"
                name="deathCareProviderCountry"
                value={formData.deathCareProviderCountry}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>
          </div>

          <div className="flex gap-4 w-full mb-4">
            <div className="w-[50%]">
              <label
                htmlFor="relationshipToDeceased"
                className="block text-sm font-medium text-gray-700"
              >
                What is your relationship to the deceased?
              </label>
              <input
                type="text"
                id="relationshipToDeceased"
                name="relationshipToDeceased"
                value={formData.relationshipToDeceased}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>
            <div className="w-[50%]">
              <label
                htmlFor="typeOfFuneralArrangement"
                className="block text-sm font-medium text-gray-700"
              >
                Type of Funeral Arrangement
              </label>
              <input
                type="text"
                id="typeOfFuneralArrangement"
                name="typeOfFuneralArrangement"
                value={formData.typeOfFuneralArrangement}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>
          </div>

          {/* <div className="mt-4 mb-4">
          <label
            htmlFor="Memorial Services"
            className="block text-sm font-medium text-gray-700"
          >
            Memorial Services
          </label>
          <textarea
            id="Memorial Services"
            name="Memorial Services"
            value={formData.obituaryText}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full h-40"
          />
        </div>
        <div className="mt-4 mb-4">
          <label
            htmlFor="Charitable Donations"
            className="block text-sm font-medium text-gray-700"
          >
            Charitable Donations
          </label>
          <textarea
            id="Charitable Donations"
            name="Charitable Donations"
            value={formData.obituaryText}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full h-40"
          />
        </div>
        <div className="mt-4 mb-4">
          <label
            htmlFor="What Were They Like"
            className="block text-sm font-medium text-gray-700"
          >
            What Were They Like
          </label>
          <textarea
            id="What Were They Like"
            name="What Were They Like"
            value={formData.obituaryText}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full h-40"
          />
        </div> */}
          {/* Submit Button */}
          <div className="flex justify-center mt-4">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
              disabled={loading}
            >
              {loading ? "Loading.." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ObituaryCreate;
