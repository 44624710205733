import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: 0,}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    start_loader: (state) => {
        console.log('start');
      state.value = 1;
    },
    stop_loader: (state) => {
        console.log('end');
      state.value = 0;
    },
  },
})

// Action creators are generated for each case reducer function
export const { start_loader, stop_loader } = loaderSlice.actions
export default loaderSlice.reducer;