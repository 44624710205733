import "./App.css";
import React from "react";
import Reports from "./pages/reports";
import Home from "./pages/home";
import Layout from "./layout";

import { useLocation, Routes, Route } from "react-router-dom";
import DataUpload from "./pages/DataUpload";
import Signin from "./pages/signin";
import { Children } from "react";
import Resetpassword from "./pages/signin/Resetpassword";
import SetPassword from "./pages/signin/SetPassword";
import Donepassword from "./pages/signin/Donepassword";
import AdminConsole from "./pages/AdminConsole";
import Dashboard from "./pages/Dashboard";
import ManegePermission from "./pages/Admin-console-user/ManagerPermissions";
import UserAdd from "./pages/Admin-console-user/UserAdd";
import KycDetails from "./pages/ClientMaster/KycDetails";
import ClientMaster from "./pages/ClientMaster";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import ClientAlert from "./pages/clientAlert";
import ObituaryDeatils from "./pages/obituary/ObituaryDetails";
import Obituarybyid from "./pages/obituary/Obituarybyid ";
import User from "./pages/User/index";
import Enquiry from "./pages/Enquiry/index";
import Obituarycreate from "./pages/obituary/Obituarycreate";
import ObituaryDeatilsUser from "./pages/User/ObituaryDeatilsUser";
import ObituaryEdit from "./pages/obituary/ObituaryEdit";
import { AuthCheck } from "./helper/AuthCheck";

function App() {
  const [title, setTitle] = React.useState("Dashboard");
  /*  case '/reports':
          tt = 'Reports';
          break;
        case '/Home':
          tt = 'Dashboard';
          break;
        case '/admin-console':
          tt = 'Admin Console';
          break;
        case '/data-uploads':
          tt = 'Data Uploads';
          break;
        case '/report-to-exchange':
          tt = 'Report To Exchange';
          break;
        case '/report-to-fiu':
          tt = 'Report To FIU';
          break;
        case '/settings':
          tt = 'settings';
           */

  const layoutWrapper = (props, title) => {
    return (
      <Provider store={store}>
        <AuthCheck>
          <Layout pageTitle={title}>{props}</Layout>
        </AuthCheck>
      </Provider>
    );
  };
  return (
    <>
      <Routes>
        {/* signin */}
        <Route
          exact
          path="/dashboard"
          element={layoutWrapper(<Home />, "Dashboard")}
        />
        {/* <Route exact path="/" element={layoutWrapper(<Home />)} /> */}
        <Route exact path="/" element={<Signin />} />
        <Route
          exact
          path="/reports"
          element={layoutWrapper(<Reports />, "Reports")}
        />
        <Route
          exact
          path="/data-uploads"
          element={layoutWrapper(<DataUpload />, "Data Upload")}
        />
        <Route
          exact
          path="/obituary-details"
          element={layoutWrapper(<ObituaryDeatils />, "Obituary List")}
        />
        <Route exact path="/user" element={layoutWrapper(<User />, "Users")} />
        <Route exact path="/enquiry" element={layoutWrapper(<Enquiry />, "Enquries")} />
        <Route
          exact
          path="/obituary-details-user/:user_id"
          element={layoutWrapper(<ObituaryDeatilsUser />, "Obituary List")}
        />
        <Route
          path="/obituary-details/:obituary_id"
          element={layoutWrapper(<Obituarybyid />, "Obituary Details")}
        />
        <Route
          exact
          path="/obituary-create"
          element={layoutWrapper(<Obituarycreate />, "Obituary Create")}
          pageTitle="Craete Obituary"
        />
        <Route
          exact
          path="/edit-obituary/:id"
          element={layoutWrapper(<ObituaryEdit />, "Obituary Edit")}
          pageTitle="Craete Obituary"
        />
        <Route
          exact
          path="/logic-defination"
          element={layoutWrapper(<ManegePermission />)}
        />
        <Route
          exact
          path="/report-to-exchange"
          element={layoutWrapper(<Home />)}
        />
        <Route
          exact
          path="/report-to-fiu"
          element={layoutWrapper(<ClientAlert />)}
        />
        <Route
          exact
          path="/client-master"
          element={layoutWrapper(<ClientMaster />)}
        />
        <Route exact path="/settings" element={layoutWrapper(<Home />)} />
        <Route exact path="/dashboard" element={layoutWrapper(<Dashboard />)} />
        <Route
          exact
          path="/manage-permission"
          element={layoutWrapper(<UserAdd />)}
        />
        <Route exact path="/add-user" element={layoutWrapper(<UserAdd />)} />
        <Route
          exact
          path="/admin-console"
          element={layoutWrapper(<AdminConsole />)}
        />
        <Route
          exact
          path="/client-master/:id"
          element={layoutWrapper(<ClientMaster />)}
        />
        <Route exact path="/resetpassword" element={<Resetpassword />} />
        <Route exact path="/setpassword" element={<SetPassword />} />
        <Route exact path="/donepassword" element={<Donepassword />} />
      </Routes>
    </>
  );
}

export default App;
