import React,{useState} from 'react'
import Roledefination from './Roledefination'
import Usercreation from './Usercreation'

const AdminConsole = () => {
    const [tab, settab] = useState('User Creation')
  return (
    <div>
    <div class="mb-4 border-b border-gray-200 ">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center margin-style" id="myTab" >
            <li class="mr-2">
                <button class={`inline-block p-4 rounded-t-lg border-b-2  ${tab=="User Creation" ?'text-[#0075fc] border-blue-600':"text-gray-500 "}  `} id="profile-tab" onClick={()=>{
                    settab('User Creation')
                }}>User Creation</button>
            </li>
            <li class="mr-2">
                <button class={`inline-block p-4 rounded-t-lg border-b-2   ${tab=="Role Defination" ?'text-[#0075fc] border-blue-600':"text-gray-500 "}`}id="dashboard-tab"  onClick={()=>{
                    settab('Role Defination')
                }}>Role Definition</button>
            </li>
        </ul>
    </div>

    {
        tab=='User Creation'&&<Usercreation/>
    }
   {
     tab=='Role Defination'&&<Roledefination/>
   }
    </div>
  )
}

export default AdminConsole