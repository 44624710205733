import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_API } from "../../helper/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spin, Tabs } from "antd";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

// const currentDate = new Date(new Date().getTime() - 86400000)
//   .toISOString()
//   .split("T")[0];

const ObituaryEdit = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    dateOfDeath: "",
    email: "",
    age: "",
    cityOfResidence: "",
    stateOfResidence: "",
    deathCareProvider: "",
    deathCareProviderCity: "",
    deathCareProviderCountry: "",
    deathCareProviderState: "",
    relationshipToDeceased: "",
    typeOfFuneralArrangement: "",
    obituaryText: "",
    placeOfBirth: "",
    MemorialServices: "",
    CharitableDonations: "",
    WhatWereTheyLike: "",
    Survivingfamilymembers: "",
    Predeceasedfamilymembers: "",
    PrimarySchool: "",
    HighSchool: "",
    College: "",
    BranchName: "",
    Occupation: "",
    Placesofworship: "",
    ListAnyHobbies: "",
    FuneralHomeName: "",
    FuneralHomeLocation: "",
  });
  const [currentDate] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });

  const fetchData = async () => {
    try {
      // Make a GET request to your API endpoint

      const response = await axios.get(BASE_API + "api/get_by_id/" + id);
      let currentObj = response.data.data;
      //   console.log(response?.data?.data?.add_img);
      Object.keys(currentObj).map((key) => {
        if (key === "dateOfBirth" || key === "dateOfDeath") {
          const date = new Date(currentObj[key]);
          const formattedDate = date.toISOString().split("T")[0];
          setFormData((prevData) => ({
            ...prevData,
            [key]: formattedDate,
          }));
          setLoading(false);
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [key]: currentObj[key],
          }));
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    // console.log(data);
    // Call the fetchData function when the component mounts
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    // const newValue = e.target.type === "file" ? [...files] : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    (async () => {
      try {
        const apiUrl = BASE_API + `api/updateObituary/${id}`;
        setLoading(true);
        // const newFormData = new FormData();
        // newFormData.append("firstName", formData.firstName);
        // newFormData.append("middleName", formData.middleName);
        // newFormData.append("lastName", formData.lastName);
        // newFormData.append("email", formData.email);
        // newFormData.append("dateOfBirth", formData.dateOfBirth);
        // newFormData.append("dateOfDeath", formData.dateOfDeath);
        // newFormData.append("age", formData.age);
        // newFormData.append("cityOfResidence", formData.cityOfResidence);
        // newFormData.append("stateOfResidence", formData.stateOfResidence);
        // newFormData.append("deathCareProvider", formData.deathCareProvider);
        // newFormData.append(
        //   "deathCareProviderCity",
        //   formData.deathCareProviderCity
        // );
        // newFormData.append(
        //   "deathCareProviderCountry",
        //   formData.deathCareProviderCountry
        // );
        // newFormData.append(
        //   "deathCareProviderState",
        //   formData.deathCareProviderState
        // );
        // newFormData.append(
        //   "relationshipToDeceased",
        //   formData.relationshipToDeceased
        // );
        // newFormData.append(
        //   "typeOfFuneralArrangement",
        //   formData.typeOfFuneralArrangement
        // );
        // newFormData.append("obituaryText", formData.obituaryText);

        const response = await axios.post(apiUrl, formData);

        if (response.data.status) {
          navigate(`/obituary-details/${id}`);
          toast.success(response.data.message);
        } else {
          console.log("some error occured.");
        }
        setLoading(false);

        console.log("Form submitted:", response.data);
      } catch (error) {
        console.error("Error submitting form:", error.message);
      }
    })();
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin size="large" className="">
          <h1 className="mt-20 text-blue-900">Loading...</h1>
        </Spin>
      </div>
    );
  }
  return (
    <div className="container mx-auto mt-8 mb-4">
      <nav className="bg-white border-gray-200 py-2.5 rounded ">
        <div className="container flex flex-wrap justify-between items-center mx-auto margin-style">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="flex items-center justify-between"
            style={{ marginTop: "-5rem" }}
          >
            <svg
              width="9"
              height="18"
              viewBox="0 0 9 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.7959 1.73977L1.81923 7.71643C1.1134 8.42227 1.1134 9.57727 1.81923 10.2831L7.7959 16.2598"
                stroke="#323232"
                strokewidth="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* <span className="self-center text-xl font-semibold whitespace-nowrap text-[#323232]  pl-3">
                  Add User
                </span> */}
          </button>
        </div>
      </nav>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-4 w-100 mb-4">
          {/* First Name */}
          <div className="w-[33%]">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
              required
            />
          </div>

          {/* Middle Name */}
          <div className="w-[33%]">
            <label
              htmlFor="middleName"
              className="block text-sm font-medium text-gray-700"
            >
              Middle Name
            </label>
            <input
              type="text"
              id="middleName"
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Last Name */}
          <div className="w-[33%]">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
              required
            />
          </div>
        </div>
        {/* date of birth ki row hai */}
        <div className="flex gap-4 w-full mb-4">
          <div className="w-[50%]">
            <label
              htmlFor="dateOfBirth"
              className="block text-sm font-medium text-gray-700"
            >
              Date of Birth
            </label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
              max={currentDate}
            />
          </div>
          <div className="w-[50%]">
            <label
              htmlFor="dateOfDeath"
              className="block text-sm font-medium text-gray-700"
            >
              Date of Death
            </label>
            <input
              type="date"
              id="dateOfDeath"
              name="dateOfDeath"
              value={formData.dateOfDeath}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
              min={formData.dateOfBirth}
              max={currentDate}
            />
          </div>
        </div>

        <div className="flex gap-4 w-full mb-4">
          <div className="w-[50%]">
            <label
              htmlFor="cityOfResidence"
              className="block text-sm font-medium text-gray-700"
            >
              Last City of Residence
            </label>
            <input
              type="text"
              id="cityOfResidence"
              name="cityOfResidence"
              value={formData.cityOfResidence}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
              required
            />
          </div>
          <div className="w-[50%]">
            <label
              htmlFor="stateOfResidence"
              className="block text-sm font-medium text-gray-700"
            >
              Last State of Residence
            </label>
            <input
              type="text"
              id="stateOfResidence"
              name="stateOfResidence"
              value={formData.stateOfResidence}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
              required
            />
          </div>
        </div>

        <div className="flex gap-4 w-full mb-4">
          <div className="w-full">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
              readOnly
              required
            />
          </div>
        </div>
        {/* Textarea for Obituary Text */}
        <div className="mt-4 mb-4">
          <label
            htmlFor="obituaryText"
            className="block text-sm font-medium text-gray-700"
          >
            Obituary Text
          </label>
          <textarea
            id="obituaryText"
            name="obituaryText"
            value={formData.obituaryText}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full h-40"
          />
        </div>

        <div className="flex gap-4 w-100 mb-4">
          {/* First Name */}
          <div className="w-[33%]">
            <label
              htmlFor="deathCareProvider"
              className="block text-sm font-medium text-gray-700"
            >
              Death Care Provider
            </label>
            <input
              type="text"
              id="deathCareProvider"
              name="deathCareProvider"
              value={formData.deathCareProvider}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Middle Name */}
          <div className="w-[33%]">
            <label
              htmlFor="deathCareProviderCity"
              className="block text-sm font-medium text-gray-700"
            >
              Death Care Provider City
            </label>
            <input
              type="text"
              id="deathCareProviderCity"
              name="deathCareProviderCity"
              value={formData.deathCareProviderCity}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Last Name */}
          <div className="w-[33%]">
            <label
              htmlFor="age"
              className="block text-sm font-medium text-gray-700"
            >
              Age
            </label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="flex gap-4 w-full mb-4">
          <div className="w-[50%]">
            <label
              htmlFor="deathCareProviderState"
              className="block text-sm font-medium text-gray-700"
            >
              Death Care Provider State
            </label>
            <input
              type="text"
              id="deathCareProviderState"
              name="deathCareProviderState"
              value={formData.deathCareProviderState}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
          <div className="w-[50%]">
            <label
              htmlFor="deathCareProviderCountry"
              className="block text-sm font-medium text-gray-700"
            >
              Death Care Provider Country
            </label>
            <input
              type="text"
              id="deathCareProviderCountry"
              name="deathCareProviderCountry"
              value={formData.deathCareProviderCountry}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="flex gap-4 w-full mb-4">
          <div className="w-[50%]">
            <label
              htmlFor="relationshipToDeceased"
              className="block text-sm font-medium text-gray-700"
            >
              What is your relationship to the deceased?
            </label>
            <input
              type="text"
              id="relationshipToDeceased"
              name="relationshipToDeceased"
              value={formData.relationshipToDeceased}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
          <div className="w-[50%]">
            <label
              htmlFor="typeOfFuneralArrangement"
              className="block text-sm font-medium text-gray-700"
            >
              Type of Funeral Arrangement
            </label>
            <input
              type="text"
              id="typeOfFuneralArrangement"
              name="typeOfFuneralArrangement"
              value={formData.typeOfFuneralArrangement}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="flex gap-4 w-100 mb-4">
          {/* First Name */}
          <div className="w-[33%]">
            <label
              htmlFor="placeOfBirth"
              className="block text-sm font-medium text-gray-700"
            >
              Place of Birth
            </label>
            <input
              type="text"
              id="placeOfBirth"
              name="placeOfBirth"
              value={formData.placeOfBirth}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Middle Name */}
          <div className="w-[33%]">
            <label
              htmlFor="MemorialServices"
              className="block text-sm font-medium text-gray-700"
            >
              Memorial Services
            </label>
            <input
              type="text"
              id="MemorialServices"
              name="MemorialServices"
              value={formData.MemorialServices}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Last Name */}
          <div className="w-[33%]">
            <label
              htmlFor="CharitableDonations"
              className="block text-sm font-medium text-gray-700"
            >
              CharitableDonations
            </label>
            <input
              type="text"
              id="CharitableDonations"
              name="CharitableDonations"
              value={formData.CharitableDonations}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="flex gap-4 w-100 mb-4">
          {/* First Name */}
          <div className="w-[33%]">
            <label
              htmlFor="WhatWereTheyLike"
              className="block text-sm font-medium text-gray-700"
            >
              What Were They Like
            </label>
            <input
              type="text"
              id="WhatWereTheyLike"
              name="WhatWereTheyLike"
              value={formData.WhatWereTheyLike}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Middle Name */}
          <div className="w-[33%]">
            <label
              htmlFor="Survivingfamilymembers"
              className="block text-sm font-medium text-gray-700"
            >
              Surviving Family Members
            </label>
            <input
              type="text"
              id="Survivingfamilymembers"
              name="Survivingfamilymembers"
              value={formData.Survivingfamilymembers}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Last Name */}
          <div className="w-[33%]">
            <label
              htmlFor="Predeceasedfamilymembers"
              className="block text-sm font-medium text-gray-700"
            >
              Predeceasedfamilymembers
            </label>
            <input
              type="text"
              id="Predeceasedfamilymembers"
              name="Predeceasedfamilymembers"
              value={formData.Predeceasedfamilymembers}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="flex gap-4 w-100 mb-4">
          {/* First Name */}
          <div className="w-[33%]">
            <label
              htmlFor="PrimarySchool"
              className="block text-sm font-medium text-gray-700"
            >
              Primary School
            </label>
            <input
              type="text"
              id="PrimarySchool"
              name="PrimarySchool"
              value={formData.PrimarySchool}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Middle Name */}
          <div className="w-[33%]">
            <label
              htmlFor="HighSchool"
              className="block text-sm font-medium text-gray-700"
            >
              High School
            </label>
            <input
              type="text"
              id="HighSchool"
              name="HighSchool"
              value={formData.HighSchool}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Last Name */}
          <div className="w-[33%]">
            <label
              htmlFor="College"
              className="block text-sm font-medium text-gray-700"
            >
              College
            </label>
            <input
              type="text"
              id="College"
              name="College"
              value={formData.College}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="flex gap-4 w-100 mb-4">
          {/* First Name */}
          <div className="w-[33%]">
            <label
              htmlFor="BranchName"
              className="block text-sm font-medium text-gray-700"
            >
              Branch Name
            </label>
            <input
              type="text"
              id="BranchName"
              name="BranchName"
              value={formData.BranchName}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Middle Name */}
          <div className="w-[33%]">
            <label
              htmlFor="Occupation"
              className="block text-sm font-medium text-gray-700"
            >
              Occupation
            </label>
            <input
              type="text"
              id="Occupation"
              name="Occupation"
              value={formData.Occupation}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Last Name */}
          <div className="w-[33%]">
            <label
              htmlFor="Placesofworship"
              className="block text-sm font-medium text-gray-700"
            >
              Places Of Worship
            </label>
            <input
              type="text"
              id="Placesofworship"
              name="Placesofworship"
              value={formData.Placesofworship}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="flex gap-4 w-100 mb-4">
          {/* First Name */}
          <div className="w-[33%]">
            <label
              htmlFor="ListAnyHobbies"
              className="block text-sm font-medium text-gray-700"
            >
              List Any Hobbies
            </label>
            <input
              type="text"
              id="ListAnyHobbies"
              name="ListAnyHobbies"
              value={formData.ListAnyHobbies}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Middle Name */}
          <div className="w-[33%]">
            <label
              htmlFor="FuneralHomeName"
              className="block text-sm font-medium text-gray-700"
            >
              Funeral Home Name
            </label>
            <input
              type="text"
              id="FuneralHomeName"
              name="FuneralHomeName"
              value={formData.FuneralHomeName}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          {/* Last Name */}
          <div className="w-[33%]">
            <label
              htmlFor="FuneralHomeLocation"
              className="block text-sm font-medium text-gray-700"
            >
              Funeral Home Location
            </label>
            <input
              type="text"
              id="FuneralHomeLocation"
              name="FuneralHomeLocation"
              value={formData.FuneralHomeLocation}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
            disabled={loading}
          >
            {loading ? "Loading.." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ObituaryEdit;
