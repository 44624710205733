import React, { useEffect, useState } from "react";
import { clientContactDetails } from "../../service/Services";
import { useLocation } from "react-router-dom";

const Others = ({ ClientData, id }) => {
  const location = useLocation();
  const [other, setOther] = useState("");
  const [Edit, setEdit] = useState(true);

  // useEffect(()=>{
  //   setOther(ClientData)
  // },[])

  // useEffect(()=>{
  //   id && fetchClientContactDetails(id)
  // },[id])

  // const fetchClientContactDetails = async (id) => {
  //   const details = await clientContactDetails(id)
  //   console.log("🚀 ~ file: Others.js ~ line 18 ~ fetchClientContactDetails ~ details", details)
  //   setOther(details)
  // }

  useEffect(() => {
    if (location.search.includes("edit")) {
      fetchclient();
    } else {
      fetchbank();
    }
  }, [Edit, id]);

  useEffect(() => {
    if (location.search.includes("true")) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, [location.search.includes("edit")]);

  const fetchclient = async () => {
    let data = await clientContactDetails(id);
    console.log("🚀 ~ file: Others.js ~ line 36 ~ Others ~ data", data);
    setOther(data.result[0]);
  };

  const fetchbank = async () => {
    let data = await clientContactDetails(id);
    console.log("🚀 ~ file: Others.js ~ line 43 ~ fetchbank ~ data", data);
    setOther(data.result[0]);
  };

  return (
    <>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg
              width="30"
              height="12"
              viewBox="0 0 30 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
                fill="#0075FC"
              />
            </svg>
            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              Exchange-Segment
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div class=" relative shadow-md p-2 flex flex-wrap justify-between">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4 overflow-x-auto">
            <table class="w-full text-sm text-left text-gray-500 border-2 border-gray-40 shadow-lg">
              <thead class="text-xs text-gray-700 uppercase bg-[#E5F0FE] ">
                <tr>
                  <th scope="col" class="py-3 px-6 text-[#0075FC]">
                    UCC Exchange segment status
                  </th>
                  <th scope="col" class="py-3 px-6 text-[#0075FC]">
                    Last Traded Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b ">
                  <th
                    scope="row"
                    class="py-2 px-6 font-medium text-gray-900 whitespace-nowrap "
                  >
                    FO
                  </th>
                  <td class="py-2 px-6">
                    <input
                      disabled={Edit}
                      type="Date"
                      placeholder="
                    "
                      className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5   "
                    />
                  </td>
                </tr>
                <tr class="bg-gray-50 border-b ">
                  <th
                    scope="row"
                    class="py-2 px-6 font-medium text-gray-900 whitespace-nowrap "
                  >
                    CD
                  </th>
                  <td class="py-2 px-6">
                    <input
                      disabled={Edit}
                      type="Date"
                      placeholder=""
                      className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5   "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4 overflow-x-auto">
            <table class="w-full text-sm text-left text-gray-500 border-2 border-gray-40 shadow-lg ">
              <thead class="text-xs text-gray-700 uppercase bg-[#E5F0FE] ">
                <tr>
                  <th scope="col" class="py-3 px-6 text-[#0075FC]">
                    UCC Exchange segment status
                  </th>
                  <th scope="col" class="py-3 px-6 text-[#0075FC]">
                    Last Traded Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b ">
                  <th
                    scope="row"
                    class="py-2 px-6 font-medium text-gray-900 whitespace-nowrap "
                  >
                    CM
                  </th>
                  <td class="py-2 px-6">
                    <input
                      disabled={Edit}
                      type="Date"
                      placeholder=""
                      className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5   "
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    class="py-2 px-6 font-medium text-gray-900 whitespace-nowrap "
                  >
                    COMM
                  </th>
                  <td class="py-2 px-6">
                    <input
                      disabled={Edit}
                      type="Date"
                      placeholder=""
                      className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5   "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg
              width="30"
              height="12"
              viewBox="0 0 30 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
                fill="#0075FC"
              />
            </svg>
            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              Client contact person details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex justify-between pb-6 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Person Name
              </div>
              <input
                disabled={Edit}
                // defaultValue={other.cm_id}
                type="text"
                placeholder="Pooja"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Contact Person Name
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder="Rahul"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5  text-[#383838] lg:text-sm text-xs">
                Contact Person PAN
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Designation
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Mobile
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder="95444 46645"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Email
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder="hsdhv@gmail.com"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Address
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder="Indore"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>

            <div className="md:flex lg:flex xl:flex gap-between items-center mt-1">
            
              <div className="flex wraapeer gap-between items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                  City
                </div>
                <input disabled={Edit}
                  defaultValue={ClientData?.City}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>

              <div className="flex wraapeer gap-between items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                  PinCode
                </div>
                <input disabled={Edit}
                  defaultValue={ClientData?.cm_pincode}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>
              
            </div>




            <div className="md:flex lg:flex xl:flex gap-between items-center mt-1">
            <div className=" wraapeer gap-between flex items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                State
                </div>
                <input disabled={Edit}
                  defaultValue={ClientData?.cm_state}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>

              <div className=" wraapeer gap-between flex items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                Country
                </div>
                <input disabled={Edit}
                  defaultValue={ClientData?.cm_country}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Relation Period Start
              </div>
              <input
                disabled={Edit}
                type="Date"
                placeholder="saving"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Relation Period End
              </div>
              <input
                disabled={Edit}
                type="Date"
                placeholder="saving"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                DIN
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex flex-wrap justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg
              width="30"
              height="12"
              viewBox="0 0 30 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
                fill="#0075FC"
              />
            </svg>
            <div className="flex">
            <span className="md:flex lg:flex xl:flex self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              Trading with other brokers <div>information details</div>
            </span>
            </div>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex justify-between pb-6 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Name of Broker
              </div>
              <input
                disabled={Edit}
                defaultValue={ClientData?.[0]?.cm_subbroker}
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Name of Sub Broker
              </div>
              <input
                disabled={Edit}
                defaultValue={ClientData?.[0]?.cm_subbroker}
                type="text"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Account Number
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder="11224 85554 5454"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                No of Years of Relationship
              </div>
              <input
                disabled={Edit}
                type="text"
                placeholder="2 Year"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Relationship Type
              </div>
              <select
                disabled={Edit}
                id="countries"
                class="bg-gray-50 border border-[#E8E2E2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5"
              >
                <option selected>Select status</option>
                <option value="US">F1</option>
                <option value="CA">F2</option>
                <option value="CA">F3</option>
                <option value="CA">F4</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex wraapeer gap-between justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg
              width="30"
              height="12"
              viewBox="0 0 30 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
                fill="#0075FC"
              />
            </svg>
            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              Employee details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex justify-between pb-6 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Employee Type
              </div>
              <input
                disabled={Edit}
                defaultValue={ClientData?.[0]?.employeeType}
                type="text"
                placeholder="Employee Type"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Association(As HO Branch)
              </div>
              <input
                disabled={Edit}
                defaultValue={ClientData?.[0]?.associationAsHOBranch}
                type="text"
                placeholder="Association(As HO Branch)"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
        </div>
      </div>
      {!Edit && (
        <div className="flex justify-center px-5 lg:px-5 py-4 mt-12">
          <button
            type="button"
            class="text-[#0075FC] bg-[#ffffff] border-2 border-[#0075FC] hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-[#ffffff] bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Submit
          </button>
        </div>
      )}
    </>
  );
};

export default Others;
