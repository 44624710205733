import LoadingIcon from '../img/loading-icon.gif';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
// import { start_loader,stop_loader } from '../redux/slice/loaderSlice';

function LoaderHandler() {
    const LoadingStatus = useSelector((state)=>state.loader.value);
    // const dispatch      = useDispatch();

  return (  
    <> 
        {LoadingStatus ? 
        <div 
        className={'fixed top-0 left-0 bg-neutral-600 w-full h-full justify-center flex  z-[10000] items-center '} 
        style={{background: 'rgba(0,0,0,.5)'}} 
        >
        <img src={LoadingIcon}  className="loading-icon"  style={{width: '100px',height: '100px'}} />
        </div>
        :""}
    </>
  )
}

export default LoaderHandler;