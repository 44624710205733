import React from 'react'
import ListItem from '@mui/material/ListItem';
import { styled, useTheme } from '@mui/material/styles';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from "react-router-dom";
import List from '@mui/material/List';
import "./sidebar.css"

// ListItemText

const ListTag = styled(ListItemText,{})(
    ({ theme })=>({
      '& .MuiListItemText-root':{
        border: '1px solid red'
      }
    })
  )

const SingleListItem = ({singleList,handleDropDown,classes,activeDropDown,open,index,activePath,handleDrawerClose})=>{
    return (
        <> 
        { singleList.childs.length?
        
          <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}

          onClick={()=>handleDropDown(index)}
          className={`list-item ${activePath===singleList.link?'active-item':'notActivate'}` }
          >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 0,
              justifyContent: 'center',
            }}
            className={`${!open && "mrs-0"}`}

          >
            <img src={singleList.icon} className={classes.iconImg+" icon"} />
          </ListItemIcon>
          <ListItemText primary={singleList.title} sx={{ opacity: 1,fontSize: '14px' }} className={`${!open && 'hidden'} text-gray-300 ml-4`}/>

          {  open && <>{(activeDropDown===index) ? <ExpandLess className="text-gray-500"/> : <ExpandMore className="grayyy"/>}</>}

          </ListItemButton>
          
          :       
           <ListItem  key={index}  disablePadding sx={{ display: 'block' }} className={`list-item ${activePath===singleList.link?'active-item':'notActivate'}` }>
                <Link  to={singleList.link} title={singleList.title} >
                    <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                    >
                    <ListItemIcon
                        sx={{
                        minWidth: 0,
                        mr: 2,
                        justifyContent: 'center',
                        }}
                        className={`${!open && "mrs-0"}`}
                    >
                        <img src={singleList.icon} className={classes.iconImg+' icon'} />
                    </ListItemIcon>
                    <ListTag  className={`${!open && 'hidden'} text-gray-300`} >{singleList.title}</ListTag>

                    </ListItemButton>
                </Link>
            </ListItem>
        }

         {singleList.childs.length>0 && 
          <Collapse in={activeDropDown===index} timeout="auto" unmountOnExit className="sidebar-li">
            {singleList.childs.map((singleChild)=>(
              <List component="div" disablePadding className={`list-item dropdown-items ${activePath===singleList.link?'active-dropdown':'not-active-dropdown'}` }>
                <Link  to={singleChild.link} title={singleChild.title} className="dropdown-link" >
                  <span className='dropdown-text text-grey-300'  >{singleChild.title}</span>
                </Link>
              </List>
            ))}
          </Collapse>
          }
        </>
    )
}
export default SingleListItem;