import React from "react";
import TransactionsTable from "./TransactionsTable";

function Transactions() {
  return (
    <>
      <div className="border rounded-md bg-[#FFFFFF] px-2 py-2">
        <div className="flex  justify-between items-center">
          <div className="items-center ml-2 py-2 px-4 w-1/5 sm:w-full  ">
            <div class="input-group relative  items-stretch w-[250px] mb-4 rounded">
              <input
                type="search"
                class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
            </div>
          </div>
          <div className="items-center   w-4/5  flex xs:w-full justify-end ">
            <div className="relative flex items-center ml-2 my-2 input-size">
              <label
                htmlFor=""
                className="text-[#0075FC] w-[100px] text-[12px]"
              >
                Assignment
              </label>
              <select
                name="start"
                type="date"
                className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:w-[150px] lg:w-[150px] xl:w-[150px] w-[100%] p-2.5  drop-shadow-md"
                placeholder="07/22/2019"
              >
                <option selected>0</option>
                <option value="US">1</option>
                <option value="CA">2</option>
                <option value="FR">3</option>
                <option value="DE">4</option>
              </select>
            </div>
            <div className="relative flex items-center ml-2 my-2 input-size xl:justify-end lg:justify-end md:justify-end justify-start">
              <button
                type="button"
                className="md:w-[120px] lg:w-[120px] xl:w-[120px] w-[100%] text-white bg-blue-700 border border-[#0075FC] font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none z-10"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        <TransactionsTable />
      </div>
    </>
  );
}

export default Transactions;
