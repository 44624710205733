import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { clientDetails } from "../../service/Services";
import { clientBankDetails, getPincode } from "../../service/Services";
import { Tooltip } from 'antd';

const Coman = ({ ClientData, Edit, id }) => {
  const location = useLocation();
  const [kyc, setkyc] = useState("");

  // useEffect(() => {
  //   // if (location.search.includes("edit")) {
  //   //   fetchclient(id);
  //   // } else {
  //   //   fetchbank(id);
  //   // }

  //   setkyc(ClientData);
  // }, [Edit, id]);

  const fetchclient = async () => {
    let data = await clientDetails(id);
    setkyc(ClientData);
  };

  const fetchbank = async () => {
    let data = await clientBankDetails(id);
    setkyc(data.result[0]);
  };

  // const [open, setIsOpen] = useState(false)
  const CheckInfo = ({title}) => {
    if(title?.length>15){
      return(
        <p className="flex items-center">
          <span className="three-dot">: {title}</span>
          <Tooltip placement="bottom" title={title}>
          <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg></span></Tooltip>
        </p>
      )
    }else{
    return(<div className="three-dot">: {title}</div>)  
    }
  }

  return (
    <div className="flex justify-between border back-forword-btn rounded-md mt-4 mb-4 flex-wrap	">
      <div className="justify-between px-2 lg:px-2 py-4">
        <div className="flex ">
          <div className="text-blue-600 w-[130px]" >Client ID </div>
          <CheckInfo title={ClientData?.cm_cd}/>
        </div>

        <div className="flex ">
          <div className="text-blue-600 w-[130px]">Client Name</div>
          <CheckInfo title={ClientData?.ClientName}/>
        </div>
      </div>

      {/* <div className="border bg-[#AFB5B7] w-[3px] my-3"></div> */}
      
      <div className="justify-between px-2 lg:px-2 py-4">
        <div className="flex">
          <div className="text-blue-600 w-[130px]">Email ID</div>
          <CheckInfo title={ClientData?.Email}/>
        </div>

        <div className="flex">
          <div className="text-blue-600 w-[130px]">Mobile Number</div>
          <CheckInfo title={ClientData?.MobileNo}/>
        </div>
      </div>
      
      {/* <div className="border bg-[#AFB5B7] w-[3px] my-3"></div> */}

      <div className="justify-between px-2 lg:px-2 py-4">
        <div className="md:flex lg:flex xl:flex flex-wrap">
          <h2 className="text-blue-600 w-[254px]">
            Risk Category as per your data
          </h2>
          <Tooltip placement="topLeft" title={ClientData?.risk_category || "High"}>
          <p className="m-0">: {ClientData?.risk_category || "High"}</p>
          </Tooltip>
        </div>

        <div className="md:flex lg:flex xl:flex flex-wrap">
          <h2 className="text-blue-600 w-[254px]">
            Risk Category as per our app{" "}
          </h2>
          <Tooltip placement="topLeft" title={ClientData?.risk_category || "Medium"}>
          <div>: {ClientData?.risk_category || "Medium"}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Coman;
