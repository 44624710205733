import React, { useState } from "react";
import Alerts from "./Alerts";
import BasicDetils from "./BasicDetils";
import Coman from "./Coman";
import Transactions from "./Transactions";

const ClientAlert = () => {
  const [tabes, settabes] = useState("Basic Detils");
  return (
    <>
      <div className="items-center md:flex lg:flex xl:flex flex-wrap">
        <div className="md:flex lg:flex xl:flex justify-start flex-wrap">
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[100px] text-[12px]">
            From Date
          </label>
          <input
            name="start"
            type="date"
            className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:w-[150px] lg:w-[150px] xl:w-[150px] w-[100%] p-2.5 drop-shadow-md"
            placeholder="07/22/2019"
          />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[100px] text-[12px]">
            To Date
          </label>
          <input
            name="start"
            type="date"
            className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block md:w-[150px] lg:w-[150px] xl:w-[150px] w-[100%] p-2.5  drop-shadow-md"
            placeholder="07/22/2019"
          />
        </div>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size xl:justify-end lg:justify-end md:justify-end justify-start">
          <button
            type="button"
            className="md:w-[120px] lg:w-[120px] xl:w-[120px] w-[100%] text-[#0075FC] border border-[#0075FC]  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none z-10"
          >
            Reset
          </button>
        </div>
      </div>

      <div class="mb-4 mt-12 border border-gray-200 rounded-md ">
        <ul
          class="w-[100%] md:flex xl:flex lg:flex flex-wrap pl-0 mt-2 mb-2 "
          id="tab-client"
        >
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              class={`w-[100%] inline-block py-4  text-sm font-medium text-center ${
                tabes == "Basic Detils"
                  ? "inline-block py-4 px-18 text-sm font-medium text-center text-white bg-blue-600 rounded-md"
                  : "text-gray-500 "
              }  `}
              id="profile-tab"
              onClick={() => {
                settabes("Basic Detils");
              }}
            >
              Basic Detils
            </button>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              class={`w-[100%] inline-block py-4  text-sm font-medium text-center  ${
                tabes == "Alerts"
                  ? "inline-block py-4 px-18 text-sm font-medium text-center text-white bg-blue-600 rounded-md"
                  : "text-gray-500 "
              }`}
              id="dashboard-tab"
              onClick={() => {
                settabes("Alerts");
              }}
            >
              Alerts
            </button>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              class={`w-[100%] inline-block py-4  text-sm font-medium text-center  ${
                tabes == "Transactions"
                  ? "inline-block py-4 px-18 text-sm font-medium text-center text-white bg-blue-600 rounded-md"
                  : "text-gray-500 "
              }`}
              id="dashboard-tab"
              onClick={() => {
                settabes("Transactions");
              }}
            >
              Transactions
            </button>
          </li>
        </ul>
      </div>

      {tabes == "Basic Detils" && <BasicDetils />}
      {tabes == "Alerts" && <Alerts />}
      {tabes == "Transactions" && <Transactions />}
    </>
  );
};

export default ClientAlert;
