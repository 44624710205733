import React,{useState,useEffect} from "react";
import {useNavigate} from "react-router-dom"
import signin from '../../layout/img/signin.png'

export default function Signin() {
    const [eye, seteye] = useState({one:false,two:false})
    const [password, setpassword] = useState({})
    const [error, seterror] = useState('')
    const [sign,setSign]=useState(false)
    let navigate = useNavigate();

    document.title = "Admin Console : : Reset Password"


//     useEffect(() => {
//    if(password.length<=8){
//     setcheck({...check,otp:""})
//    }
//     }, [password])
    

    const change = (e) =>{
        let Obj = {...password}
        Obj[e.target.name] = e.target.value;
        setpassword({...Obj}) 
    }

    const validate = () =>{
        setSign(true)
       if(password.one !== password.two){
            seterror('*Both password must be same!');
            return
       }
       if(password.one <=8 || password.two <= 8||!password.one||!password.two){
        seterror('Password must be greter then 8 or not be empty');
        return
   }
   navigate("/donepassword", { replace: true })    }

    return (
        <>
            <section class="h-screen">
                <div class=" h-full w-full">
                    <div class="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
                        <div class=" md:w-6/12 mb-12 md:mb-0 w-full">
                            <img
                                src={signin}
                                class="w-full h-[100vh] md:block lg:block xl:block "
                                alt="Phone image"
                            />
                            
                        </div>
                        
                        <div class=" md:w-5/12 border-0 p-8 shadow-2xl sm:w-[80%] sm:m-auto lg:w-5/12">
                            <div className="flex justify-center">
                                <svg width="35" height="35" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="23" fill="#0075FC"/>
                                </svg>
                                <div className="w-100 text-center mb-6 font-bold text-2xl ml-2">Logo</div>
                            </div>
                            <div className="mb-6 font-bold text-2xl"><span className="border-b-4 border-blue-600 pb-1">Set</span> New Password</div>
                            <div>
                                <div class="mb-6 relative">
                                    <div className='my-2 font-bold'>Password</div>
                                    <input
                                        value={password.one}
                                        onChange={(e)=>{
                                           setpassword(e.target.value);change(e)
                                        }}
                                        type={ eye.one  ? 'text':'password'}
                                        class=" text-[0.75rem]  form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Enter Your Password"
                                        name="one"
                                    />

                                    <svg onClick={()=>{
                                        seteye({...eye,one:!eye.one})
                                    }} class="absolute top-[47px] right-[13px] bi bi-eye"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={!eye ? 'blue':'grey'}  viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                </div>
                                <div class="mb-6 relative">
                                    <div className='my-2 font-bold'>Confirm Password</div>
                                    <input
                                        value={password.two}
                                        onChange={(e)=>{
                                           setpassword(e.target.value);change(e)
                                        }}
                                        type={ eye.two  ? 'text':'password'}
                                        class=" text-[0.75rem]  form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Enter Your confirm Password"
                                        name="two"
                                    />
                                    {sign&&<div className='text-[0.75rem] text-[#FF0000]'>{error}</div>}

                                    <svg onClick={()=>{
                                        seteye({...eye,two:!eye.two})
                                    }} class="absolute top-[47px] right-[13px] bi bi-eye"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={!eye ? 'blue':'grey'}  viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                </div>
                                <button
                                    type=""
                                    class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-[175px]"
                                   
                                    onClick={()=>{validate();
                                        

                                    }}
                                >Reset Password</button>

                              
                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

