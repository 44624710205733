import React, { useState, useEffect } from "react";
import ReportTable from "../reports/ReportTable";
import { GetCall, PostCall } from "../../service/Services";
import axiosInstance from "../../service/ApiCall";
import "./style.css";
import Enquirytable from "./Enquirytable";
import AddDocumentType from "../../components/AddDocumentType";
import axios from "axios";
import { BASE_API } from "../../helper/Api";
import { Spin, Tabs } from "antd";
import { Link, useLocation } from "react-router-dom";

export default function Enquiry() {
  const { state } = useLocation();
  const [isLoading, setIsLocation] = React.useState(false);
  const [reportsData, setReportsData] = React.useState([]);
  const [model, setmodel] = useState(false);
  const [dataFrom, setDateFrom] = React.useState("7-10-2022");
  const [dataTo, setDateTo] = React.useState("7-13-2022");
  const [alertType, setAlertType] = React.useState("BSE");
  const [clientID, setClientID] = React.useState("TCRT636");
  const [data, setData] = useState();
  const [blockData, setBlockData] = useState([]);
  const [unblockData, setUnblockData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      // Make a GET request to your API endpoint
      // const response = await axios.get(BASE_API + "api/user-list");

      const response = await axios.get(BASE_API + "api/all-enquiry");
      console.log(
        "======================================================================="
      );
      console.log(response.data.data);

      const ar = [];
      for (let index = 0; index < response.data.data.length; index++) {
        const element = response.data.data[index];
        const obj = {
          // index: index + 1,
          ...element,
        };
        ar.push(obj);
      }

      let unblockData = ar.map((item, i) => ({ index: i + 1, ...item }));
      // setData(currentData);
      setUnblockData(unblockData);
      setLoading(false);
      // let currentData = ar
      //   .filter((item) => item.published === dataType)
      //   .map((item, i) => ({ index: i + 1, ...item }));
      setData(ar);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const items = [
    {
      key: "1",
      label: "Active User",
      children: (
        <Enquirytable
          dataType={0}
          data={unblockData}
          setData={setUnblockData}
          fetchData={() => fetchData()}
        />
      ),
    },
    {
      key: "2",
      label: "Block User",
      children: (
        <Enquirytable
          dataType={1}
          data={blockData}
          setData={setBlockData}
          fetchData={() => fetchData()}
        />
      ),
    },
  ];

  const handleTabChange = (data) => {
    console.log({ data });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin size="large" className="">
          <h1 className="mt-20 text-blue-900">Loading...</h1>
        </Spin>
      </div>
    );
  }

  // React.useEffect(() => {
  //   setIsLocation(true);
  //   fetchData();
  //   setIsLocation(false);
  // }, [dataFrom, dataTo, alertType, clientID]);

  return (
    <>
      <div className="">
        <Enquirytable
          dataType={0}
          data={unblockData}
          setData={setUnblockData}
          fetchData={() => fetchData()}
        />
        {/* <Tabs
          defaultActiveKey={state?.activeTab || "1"}
          items={items}
          onChange={(activeKey) => handleTabChange(activeKey)}
        /> */}
        {/* <ObituaryTable reportData={reportsData} /> */}
      </div>
    </>
  );
}
