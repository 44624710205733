import React, { useEffect, useState } from "react";

import LineChartDashboard from "./LineChartDashboard";
import BarChartDashboard from "./BarChartDashboard";
import ExchangeTbl from "./ExchangeTbl";
import ClientTble from "./ClientTbl";
import AlertTbl from "./Alerts";
import UserTbl from "./UserTbl";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_API } from "../../helper/Api";
import {
  ArcElement,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Spin } from "antd";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function Home() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    labels: ["Label 1", "Label 2"],
    datasets: [
      {
        data: [30, 50], // Replace with your data
        backgroundColor: ["#FF6384", "#36A2EB"], // Replace with your colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB"], // Replace with your hover colors
      },
    ],
  });
  const [barChartData, setBarChartData] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Total",
        data: [1, 2, 3, 4, 1, 5, 6],
        backgroundColor: "rgb(53, 162, 235)",
        // stack: "Stack 0",
      },
      {
        label: "Published",
        data: [1, 2, 3, 4, 1, 5, 6],
        backgroundColor: "rgb(75, 192, 192)",
        // stack: "Stack 1",
      },
      {
        label: "Pending",
        data: [1, 2, 3, 4, 1, 5, 6],
        backgroundColor: "rgb(255, 99, 132)",
        // stack: "Stack 0",
      },
    ],
  });

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const userOptions = {
    plugins: {
      title: {
        display: true,
        text: "USERS",
      },
    },
    responsive: true,
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "OBITUARY",
      },
    },
    responsive: true,
    // interaction: {
    //   mode: "index",
    //   intersect: false,
    // },
    // scales: {
    //   x: {
    //     stacked: true,
    //   },
    //   y: {
    //     stacked: true,
    //   },
    // },
  };

  const fetchData = async () => {
    try {
      // Make a GET request to your API endpoint
      // const response = await axios.get(BASE_API + "api/user-list");
      const response = await axios.get(BASE_API + "api/dashboard-data");
      console.log(response.data);

      setChartData({
        labels: ["Active", "Blocked"],
        datasets: [
          {
            data: [response.data.activeUser, response.data.deactiveUser], // Replace with your data
            backgroundColor: ["#008fd7", "#FF6384"], // Replace with your colors
            hoverBackgroundColor: ["#008fd7", "#FF6384"], // Replace with your hover colors
          },
        ],
      });
      setBarChartData({
        labels: [...response.data.barChartData.labels].reverse(),
        datasets: [
          {
            label: "Total",
            data: [...response.data.barChartData.totalObituary].reverse(),
            backgroundColor: "#008fd7",
            // stack: "Stack 0",
          },
          {
            label: "Published",
            data: [...response.data.barChartData.publishedObituary].reverse(),
            backgroundColor: "#84bc04",
            // stack: "Stack 1",
          },
          {
            label: "Pending",
            data: [...response.data.barChartData.pendingObituary].reverse(),
            backgroundColor: "rgb(255, 99, 132)",
            // stack: "Stack 0",
          },
        ],
      });

      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log(data);

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="h-5/6 flex justify-center items-center">
        <Spin size="large" className="">
          <h1 className="mt-20 text-blue-900">Loading...</h1>
          {/* <div className="content" /> */}
        </Spin>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mt-5">
        {/* Card 1 */}
        <div className="bg-white p-4 rounded-md text-center shadow-md">
          <Link to="/obituary-details">
            <h3 className="text-lg font-semibold mb-2">Total Obituaries</h3>
            <h3 className="text-3xl font-semibold text-center mb-2">{` ${
              data?.totalObtuary || 0
            }`}</h3>
          </Link>
        </div>

        {/* Card 2 */}
        <div className="bg-white p-4 rounded-md text-center shadow-md">
          <Link to="/obituary-details" state={{ activeTab: "1" }}>
            <h3 className="text-lg font-semibold mb-2">Pending Obituaries</h3>
            <h3 className="text-3xl font-semibold text-center mb-2">{` ${
              data?.pendingObtuary || 0
            }`}</h3>
          </Link>
        </div>

        {/* Card 3 */}
        <div className="bg-white p-4 rounded-md text-center shadow-md">
          <Link to="/obituary-details" state={{ activeTab: "2" }}>
            <h3 className="text-lg font-semibold mb-2">Published Obituaries</h3>
            <h3 className="text-3xl font-semibold text-center mb-2">{` ${
              data?.publishedObtuary || 0
            }`}</h3>
          </Link>
        </div>

        {/* Card 4 */}
        <div className="bg-white p-4 rounded-md text-center shadow-md">
          <Link to="/obituary-details" state={{ activeTab: "2" }}>
            <h3 className="text-lg font-semibold mb-2">Featured Obituaries</h3>
            <h3 className="text-3xl font-semibold  text-center mb-2">{` ${
              data?.featuredObtuary || 0
            }`}</h3>
          </Link>
        </div>

        {/* Card 5 */}
        <div className="bg-white p-4 rounded-md text-center shadow-md">
          <Link to="/user">
            <h3 className="text-lg font-semibold mb-2">Total User</h3>
            <h3 className="text-3xl font-semibold text-center mb-2">{`${
              data?.totalUser || 0
            }`}</h3>
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-5">
        {/* Card 1 */}
        <div className="bg-white p-4 rounded-md text-center shadow-md col-span-1">
          <Doughnut options={userOptions} data={chartData} />
        </div>

        {/* Card 2 */}
        <div className="bg-white p-4 rounded-md text-center shadow-md col-span-2">
          <Bar options={options} data={barChartData} />
        </div>
      </div>
      {/* <div>
        <DonutChart
          data={[
            {
              label: "Give you up",
              value: 25,
            },
            {
              label: "",
              value: 75,
              isEmpty: true,
            },
          ]}
        />
      </div> */}
      {/* <div>
        <DonutChart
          data={[
            {
              label: "Give you up",
              value: 25,
            },
            {
              label: "",
              value: 75,
              isEmpty: true,
            },
          ]}
        />
      </div> */}
      {/* <div className="grid md:grid-cols-2 sm:grid-cols-12  bg-[#fcfbfc]  gap-4 margin-style">
        <div className="p-2  shadow-md  ">
          <div className="flex justify-between">
            <h2 className="text-[#323232] text-[14px] font-bold">
              MONTH WISE ANALYSIS OF ALERTS
            </h2>
            <div className="color-section flex gap-5">
              <span className=' flex justify-center items-center text-[10px]  relative after:absolute pl-[20px] after:w-[15px] after:h-[15px] after:content-[""] after:border after:left-0 after:bg-[#13C5DD] after:rounded-full after:top-1   '>
                Internal Alerts
              </span>
              <span className=' flex justify-center items-center text-[10px]  relative after:absolute pl-[20px] after:w-[15px] after:h-[15px] after:content-[""] after:border after:left-0 after:bg-[#FBB54B] after:rounded-full after:top-1   '>
                Alerts from Exchange
              </span>
              <span className=' flex justify-center items-center text-[10px]  relative after:absolute pl-[20px] after:w-[15px] after:h-[15px] after:content-[""] after:border after:left-0 after:bg-[#43B100] after:rounded-full after:top-1   '>
                2021 - 2022
              </span>
            </div>
          </div>
          <div className="py-2 w-[100%]" style={{ height: "15rem" }}>
            <BarChartDashboard />
          </div>
        </div>

        <div className="p-2  shadow-md  ">
          <div className="flex justify-between">
            <h2 className="text-[#323232] text-[14px] font-bold">
              RISK WISE ALERT{" "}
            </h2>
            <div className="color-section flex gap-5 ">
              <span className=' flex justify-center items-center text-[10px]  relative after:absolute pl-[20px] after:w-[15px] after:h-[15px] after:content-[""] after:border after:left-0 after:bg-[#FFBA69] after:rounded-full after:top-1   '>
                Low Alert
              </span>
              <span className=' flex justify-center items-center text-[10px]  relative after:absolute pl-[20px] after:w-[15px] after:h-[15px] after:content-[""] after:border after:left-0 after:bg-[#FF2C2C] after:rounded-full after:top-1   '>
                Medium Alert
              </span>
              <span className=' flex justify-center items-center text-[10px]  relative after:absolute pl-[20px] after:w-[15px] after:h-[15px] after:content-[""] after:border after:left-0 after:bg-[#43B100] after:rounded-full after:top-1   '>
                High Alert
              </span>
            </div>
          </div>
          <div className="py-2 w-[100%]" style={{ height: "15rem" }}>
            <LineChartDashboard />
          </div>
        </div>
        <div className="p-2  shadow-md sm:grid-cols-12 ">
          <ExchangeTbl table_data={echange_tbl_data} />
        </div>
        <div className="p-2  shadow-md  ">
          <ClientTble table_data={client_tbl_data} />
        </div>
        <div className="p-2  shadow-md  ">
          <AlertTbl table_data={alert_tbl_data} />
        </div>
        <div className="p-2  shadow-md  ">
          <UserTbl table_data={user_tbl_data} />
        </div>
      </div> */}
    </>
  );
}

export default Home;
