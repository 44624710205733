import React, { useEffect, useState } from "react";
import { clientBankDetails, getPincode } from "../../service/Services";
import 'antd/dist/antd.css';
import moment from 'moment';
import { DatePicker, Slider, Switch  } from 'antd';
import { useLocation } from "react-router-dom";

// const { RangePicker } = DatePicker;


const dateFormat = 'YYYY/MM/DD';
function KycDetails({ ClientData,id}) {
  const [pincode, setpincode] = useState("");
  const [date, setdate] = useState("2001/02/22");
  const [disabled, setDisabled] = useState(false);
  const [Edit, setEdit]             = useState(true)
  const location                    = useLocation()
  const [kyc,setkyc]=useState("")

  console.log("🚀 ~ file: KycDetails.js ~ line 19 ~ KycDetails ~ kyc", kyc)



  useEffect(() => {
    getPincodeKyc(ClientData?.cm_pincode);
  }, [ClientData?.cm_pincode]);

  useEffect(() => {
    let newDate = moment(ClientData?.cm_dob).format('yyyy/MM/DD')
    setdate(newDate)
  }, [ClientData?.cm_dob]);


  useEffect(()=>{
    if(location.search.includes("edit")) {
      fetchclient()
    } else {
  fetchbank()
    }
  },[Edit,id])


  useEffect(()=>{

    if(location.search.includes("true")) {
      setEdit(false) ;
    } else{
      setEdit(true) ;

    }
  },[location.search.includes("edit")])


const fetchclient = async() => 
{
// console.log("🚀 ~ file: KycDetails.js ~ line 70 ~ KycDetails ~ data", data)
// setkyc(data.result[0])

}

const fetchbank = async()=>{
  let data = await clientBankDetails(id)
  console.log("🚀 ~ file: KycDetails.js ~ line 75 ~ fetchbank ~ data", data)
  setkyc(data.result[0])

}

  const getPincodeKyc = async (pincode) => {
    let data = await getPincode(pincode);
    data.result[0].Status == "Success" &&
      setpincode(data.result[0].PostOffice[0]);
  };

  return (
    <>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg
              width="30"
              height="12"
              viewBox="0 0 30 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
                fill="#0075FC"
              />
            </svg>

            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              Client Basic Details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex flex-wrap justify-between pb-6 ">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Client Name
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.ClientName}
                type="text"
                placeholder="0000 0000 0000"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
               
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                First Name
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.ClientName?.split(" ")[0]}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Middle Name
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.ClientName?.split(" ")[1]}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Last Name
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.ClientName?.split(" ")[2]}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Client Code
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.cm_cd}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                DOB / Registration
              </div>
              <div class="datepicker relative form-floating mb-3 w-full mt-1">
              <DatePicker className="date-size" disabled={Edit} defaultValue={moment(date, dateFormat)} format={dateFormat} value={moment(date, dateFormat)} />
                {/* <input disabled={Edit}
                  type="date"
                  defaultValue={date}
                  class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#F5F2F2] rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                /> */}
                {/* <DatePicker showTime onChange={onChange} onOk={onOk} defaultPickerValue={date}/> */}
              </div>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                PAN No.
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.cm_panno}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Email
              </div>
              <input disabled={Edit}
                type="text"
                placeholder="@"
                defaultValue={ClientData?.Email}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Mobile No.
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.MobileNo}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Gender
              </div>
              <select  disabled={Edit}
                id="countries"
                defaultValue={ClientData?.cm_sex}
                class="mr-2 bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block md:w-[72%] lg:w-[72%] xl:w-[72%] w-[100%] p-2.5   "
              >
                <option selected>Male</option>
                <option value="US">Female</option>
                <option value="CA">Other</option>
              </select>
              <div className=" font-medium w-1/2 text-[#383838] lg:text-sm text-xs">
                <div class="flex items-center py-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <input disabled={Edit}
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 "
                  />
                  <label
                    for="checkbox-item-11"
                    class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                  >
                    <span className="md:block lg:block xl:block flex">Special <div>&nbsp;Category</div></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                CIN Regi. No.
              </div>
              <input disabled={Edit}
                type="text"
                placeholder="Type CIN no."
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Client Status
              </div>
              <select  disabled={Edit}
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option value="US" selected>
                  Active
                </option>
                <option value="CA">Inactive</option>
              </select>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Place of Incorporation
              </div>
              <select  disabled={Edit}
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option selected>Select status</option>
                <option value="US">F1</option>
                <option value="CA">F2</option>
                <option value="CA">F3</option>
              </select>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Aadhar No.
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.AadharNumber}
                type="text"
                placeholder="Aadhar Number"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Education
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.Education}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Sub Broker PAN
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.SubBrokerPAN}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Address
              </div>
              {console.log("kyc", kyc)}
              <input disabled={Edit}
                type="text"
                value={
                  ClientData?.clientAddress1 +
                  "," +
                  ClientData?.clientAddress2 +
                  "," +
                  ClientData?.clientAdress3 +
                  "," +
                  ClientData?.cm_add4
                }
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="md:flex lg:flex xl:flex gap-between items-center mt-1">
            
              <div className="flex wraapeer gap-between items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                  City
                </div>
                <input disabled={Edit}
                  defaultValue={ClientData?.City}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>

              <div className="flex wraapeer gap-between items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                  PinCode
                </div>
                <input disabled={Edit}
                  defaultValue={ClientData?.cm_pincode}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>
              
            </div>
          
            {/* <div className=" items-center mt-1 grid grid-cols-2 ">
              <div className="flex justify-between items-center">
                <label className=" font-medium   text-[#383838] lg:text-sm text-xs">
                  State
                </label>
                <input disabled={Edit}
                  defaultValue={pincode?.State}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>

              <div clasName="flex w-1/2 justify-between items-center ml-[40px] ">
                <label className=" font-medium text-[#383838] lg:text-sm text-xs pl-10">
                  Country
                </label>
                <input disabled={Edit}
                  defaultValue={pincode?.Country}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>
            </div> */}



            <div className="md:flex lg:flex xl:flex gap-between items-center mt-1">
            <div className=" wraapeer gap-between flex items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                State
                </div>
                <input disabled={Edit}
                  defaultValue={pincode?.cm_state}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>

              <div className=" wraapeer gap-between flex items-center mt-1 ml-2 md:w-[50%] lg:w-[50%] xl:w-[50%] w-[100%]">
                <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                Country
                </div>
                <input disabled={Edit}
                  defaultValue={ClientData?.cm_country}
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>
            </div>

            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                UCC Category
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.UccCategor}
                className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Branch
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.cm_bankbranch}
                className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Group
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.cm_groupcd}
                className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Family
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.cm_familycd}
                className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                AP 1
              </div>
              <input disabled={Edit}
                type="text"
                className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                AP 2
              </div>
              <input disabled={Edit}
                type="text"
                className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                RM
              </div>
              <input disabled={Edit}
                type="text"
                placeholder="RM Pan"
                defaultValue={ClientData?.RM_PAN}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Introducer
              </div>
              <input disabled={Edit}
                type="text"
                defaultValue={ClientData?.cm_introducer}
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Address Type
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.AddressType}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Introducer PAN
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.IntroducerPAN}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                RM PAN
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.RM_PAN}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Dealer PAN
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.DealerPAN}
                datepicker
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>


          </div>
        </div>
      </div>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg
              width="30"
              height="12"
              viewBox="0 0 30 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
                fill="#0075FC"
              />
            </svg>

            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              Passport Details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex flex-wrap justify-between pb-6 ">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2  lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Passport No.
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.PassportNo}
                type="text"
                placeholder="0000 0000 0000"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Issue Date
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.PassportIssueDate}
                type="text"
                placeholder="BPSBPL"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
           
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2  lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Country
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.PassportCountry}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Expiry Date
              </div>
              <input disabled={Edit}
                defaultValue={ClientData?.PassportExpiryDate}
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
           
            {/* <div className="flex items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                UCC Category
              </div>
              <select
                defaultValue={ClientData?.subBrokerPAN}
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              >
                <option className="text-[#383838] text-sm" selected></option>
                <option value="US">Female</option>
                <option value="CA">Other</option>
              </select>
            </div> */}
          
          </div>
        </div>
      </div>

      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg
              width="30"
              height="12"
              viewBox="0 0 30 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
                fill="#0075FC"
              />
            </svg>

            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              FATCA Details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex flex-wrap justify-between pb-6 ">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2  lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Status
              </div>
              <select  disabled={Edit}
                id="countries"
                class="bg-gray-50 border border-[#E8E2E2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option selected>Reportable</option>
                <option value="US">Non Reportable</option>
              
              </select>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Due-Diligence
              </div>
              <select  disabled={Edit}
                id="countries"
                class="bg-gray-50 border border-[#E8E2E2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option selected>Complete</option>
                <option value="US">Incomplete</option>
                <option value="CA">Not Required</option>
              </select>
            </div>


          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2  lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Declaration
              </div>
              <select disabled={Edit}
                id="countries"
                class="bg-gray-50 border border-[#E8E2E2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option selected>Received</option>
                <option value="US">Not Received</option>
                <option value="CA">Not Required</option>
                
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z" fill="#0075FC" />
            </svg>

            <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#0075FC]  pl-3">
            Networth details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex flex-wrap justify-between pb-6 ">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2  lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
              Networth
              </div>
              <input disabled={Edit}
                type="text"
                placeholder="0000 0000 0000"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              As on Date
              </div>
              <input disabled={Edit} 
                // defaultValue={ClientData?.dP_NomineeGuardianPAN}
                type="Date"
                value="2001/08/02"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
              Networth as per RM (values in lakh)
              </div>
              <Slider className="w-[100%]" defaultValue={30} disabled={Edit} />
              {/* Disabled: <Switch size="small" checked={disabled} onChange={onChange} /> */}
              {/* <input disabled={Edit}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              /> */}
            </div>
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2  lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
              Income Range
              </div>
              <input disabled={Edit}
                type="text"
                placeholder="0000 0000 0000"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
              As on Date
              </div>
              <input disabled={Edit} 
                // defaultValue={ClientData?.dP_NomineeGuardianPAN}
                type="Date"
                value="2001/08/02"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
        </div>
      </div>
      {!Edit&&<div className="flex justify-center px-5 lg:px-5 py-4 mt-12">
          <button
            type="button"
            class="text-[#0075FC] bg-[#ffffff] border-2 border-[#0075FC] hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-[#ffffff] bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-sm px-12 py-2.5 mr-2 mb-2 focus:outline-none"
          >
            Submit
          </button>
        </div>}
    </>
  );
}

export default KycDetails;
