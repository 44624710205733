import React, { useState } from "react";
import ReportTable from "../reports/ReportTable";
import { GetCall, PostCall } from "../../service/Services";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosInstance from "../../service/ApiCall";
import "./style.css";
import Usertable from "./Usertable";
import ObituaryUserTable from "./ObituaryUserTable";
import AddDocumentType from "../../components/AddDocumentType";

import axios from "axios";
import { BASE_API } from "../../helper/Api";
import { Spin, Tabs } from "antd";

export default function ObituaryDeatilsUser() {
  const navigate = useNavigate();
  // const [isLoading, setIsLocation] = React.useState(false);
  const [reportsData, setReportsData] = React.useState([]);
  const [model, setmodel] = useState(false);
  const [dataFrom, setDateFrom] = React.useState("7-10-2022");
  const [dataTo, setDateTo] = React.useState("7-13-2022");
  const [alertType, setAlertType] = React.useState("BSE");
  const [clientID, setClientID] = React.useState("TCRT636");
  const [loading, setLoading] = useState(true);
  const FetchData = async () => {};
  React.useEffect(() => {
    // setIsLocation(true);
    FetchData();
    // setIsLocation(false);
    setLoading(false);
  }, [dataFrom, dataTo, alertType, clientID]);

  const items = [
    {
      key: "1",
      label: "Pending Obituaries",
      children: <ObituaryUserTable dataType={0} reportData={reportsData} />,
    },
    {
      key: "2",
      label: "Published Obituaries",
      children: <ObituaryUserTable dataType={1} reportData={reportsData} />,
    },
  ];

  // const items = [
  //   {
  //     key: "1",
  //     label: "Pending Obituaries",
  //     children: <ObituaryTable dataType={0} reportData={reportsData} />,
  //   },
  //   {
  //     key: "2",
  //     label: "Published Obituaries",
  //     children: <ObituaryTable dataType={1} reportData={reportsData} />,
  //   },
  // ];
  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin size="large" className="">
          <h1 className="mt-20 text-blue-900">Loading...</h1>
        </Spin>
      </div>
    );
  }

  return (
    <>
      <div className="">
        <nav className="bg-white border-gray-200 py-2.5 rounded ">
          <div className="container flex flex-wrap justify-between items-center mx-auto margin-style">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="flex items-center justify-between"
            >
              <svg
                style={{ marginTop: "0.125rem" }}
                width="9"
                height="18"
                viewBox="0 0 9 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.7959 1.73977L1.81923 7.71643C1.1134 8.42227 1.1134 9.57727 1.81923 10.2831L7.7959 16.2598"
                  stroke="#323232"
                  strokewidth="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {/* <span className="self-center text-xl font-semibold whitespace-nowrap text-[#323232]  pl-3">
                  Add User
                </span> */}
            </button>
          </div>
        </nav>
        {/* <div className="relative flex items-center ml-2 my-2 input-size">
            <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
              Date
            </label>
            <input
              name="start"
              type="date"
              className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[120px]  p-2.5  drop-shadow-md"
              placeholder="07/22/2019"
              value={dataFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </div>
          <div className="relative flex items-center ml-2 my-2 input-size">
            <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
              State
            </label>
            <select
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              onChange={(e) => setAlertType(e.target.value)}
            >
              <option>AL</option>
              <option>AI</option>
            </select>
          </div>
          <div className="relative flex items-center ml-2 my-2 input-size">
            <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
              FirstName
            </label>
            <select
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              onChange={(e) => setAlertType(e.target.value)}
            >
              <option>Adam</option>
              <option>Mick</option>
            </select>
          </div>
          <div className="relative flex items-center ml-2 my-2 input-size">
            <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
              LastName
            </label>
            <select
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              onChange={(e) => setAlertType(e.target.value)}
            >
              <option>Thomas</option>
              <option>Luther</option>
            </select>
          </div> */}
        {/* <div className="relative flex items-center justify-end">
          <Link to="/obituary-create">
            <button
              onClick={() => setmodel(true)}
              type="button"
              className="text-white w-[200px] bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 z-10"
            >
              Create Obituary
            </button>
          </Link>
        </div> */}
      </div>
      <div className="">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </>
  );
}
