// const BACKEND_URL = "http://secmark.centralindia.cloudapp.azure.com/";
// const BACKEND_URL = "https://customerexchangeapi.azurewebsites.net/";
// const BACKEND_URL = "https://customerexchangeapi.azurewebsites.net/";
const BACKEND_URL = "https://clientdataheader.azurewebsites.net/Home/";

// const bankDetailsUrl = 'https://tradeamlapi.azurewebsites.net/api/Masters/'; 
/* http://secmark.centralindia.cloudapp.azure.com/Clientdetails */
// const BACKEND_URL = "https://secmark.centralindia.cloudapp.azure.com:5000/api/Masters/";
// const BACKEND_URL = "https://secmark.centralindia.cloudapp.azure.com/api/Masters/";

const BASE_URL = 'https://tradeamlapi.azurewebsites.net/api/Masters/';

export const PostCall = async (url, data) => {
  try {
    let res = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    let result = await res.json();
    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message }
  }
}

export const GetCall = async (url, data = '') => {
  try {
    let res = await fetch(BACKEND_URL + url);
    let result = await res.json();
    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message }
  }
}

export const PostHeader = async (url, data) => {
  try {
    let res = await fetch("https://clientdataheader.azurewebsites.net/Home/Clientheader", {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }

    })
    let result = res
    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message }
  }
}

export const GetData = async (url, sendData = '') => {
  try {
    let res = await fetch("https://clientdataheader.azurewebsites.net/Home/Clientdetails", {
      method: 'POST',
      body: JSON.stringify(sendData),
      headers: {
        "Content-Type": "application/json"
      }
    })
    let result = await res.json();
    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message }
  }
}

export const clientBankDetails = async (id) => {
  try {
    let res = await fetch(
      BASE_URL + `ClientBankDetails?ClientId=`+id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let result = await res.json();

    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }
};  


export const getPincode = async (id) => {
  try {
    let res = await fetch(
      "https://api.postalpincode.in/pincode/" + id,
      
    );

    let result = await res.json();
    console.log("🚀 ~ file: Services.js ~ line 104 ~ getPincode ~ result", result)
    console.log("🚀 ~ file: Services.js ~ line 104 ~ getPincode ~ result", result)

    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }
};  

export const GetClintList = async (id)=>{
return false;
  // try {
  //   let api = "https://tradeamlapi.azurewebsites.net/api/Masters/ClientDetailsOrderBy/" + (id ? `?ClientId=${id}` :"")

  //   let res= await fetch(api);
   
  //   let result = await res.json();
    
  //   return { status: true, result: result };
  // } catch (e) {
  //   return { status: false, msg: "Something went Wrong Error: " + e.message };
  // }

}



export const clientDetails = async (id) => {
  return false;
};  

export const clientBrokerDetails = async (id) => {
  try {
    let res = await fetch(
      BASE_URL + `ClientBrokerDetails?cm_cd=`+id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let result = await res.json();

    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }
}; 

export const clientCorporateExposureDetails = async (id) => {
  try {
    let res = await fetch(
      BASE_URL + `ClientCorporateExposureDetails?ClientId=`+id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let result = await res.json();

    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }
}; 

export const clientDpDetails = async (id) => {
  try {
    let res = await fetch(
      BASE_URL + `ClientDpDetails?ClientId=`+id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let result = await res.json();

    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }
}; 

export const clientContactDetails = async (id) => {
  try {
    let res = await fetch(
      BASE_URL + `ClientContactDetails?clientId=`+id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let result = await res.json();

    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }
}; 

export const clientTpDetails = async (id) => {
  try {
    let res = await fetch(
      BASE_URL + `ClientTpDetails?clientId=`+id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let result = await res.json();

    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }
};

const GlobalBaseAPI = 'https://tradeamlapi.azurewebsites.net/';
export const GlobalCallGet = async (url,param='')=>{
  // new Promise(async (resolve, reject)=>{
    try{  
      let res     = await fetch(GlobalBaseAPI+url);
      let result  = await res.json();
      return({status:true,result: result});
    }catch(e){
      return({status:false,msg: 'Something Went Wrong Error : '+e.message});
    }
  // });
} 
export const FetchHeader = async (fileid,accid)=>{

  try {
    let api = `https://clientdataheader.azurewebsites.net/Home/Clientheader?FileAuditInfoID=${fileid}?accountno=${accid}`

    let res= await fetch(api);
   
    let result = await res.json();
    
    return { status: true, result: result };
  } catch (e) {
    return { status: false, msg: "Something went Wrong Error: " + e.message };
  }

}


export  const clientMasterDetails  = async (url,params)=>{
  try{    
    let res    = await fetch(GlobalBaseAPI+url+params);
    let result = await res.json();
    return { status: true, result: result };
  }catch(e){
    return ({status:false,result:[], msg: e.message})
  }
}