import { FamilyRestroomRounded } from '@mui/icons-material'
import React, {useEffect, useState} from 'react'
import { clientDpDetails } from '../../service/Services';
import { useLocation } from "react-router-dom";


const DpDetails = ({ClientData,id}) => {

  const [DpDetails, setDpDetails] = useState("")
  const [Edit, setEdit] = useState(true)
  const location  = useLocation()

  useEffect(()=>{
    setDpDetails(ClientData)
  },[])

  useEffect(()=>{

    if(location.search.includes("true")) {
      setEdit(false) ;
    } else{
      setEdit(true) ;

    }
  },[location.search.includes("edit")])

  useEffect(()=>{
    id && fetchClientDpDetails(id);
  },[id])

  const fetchClientDpDetails = async (id) => {
    const Dp = await clientDpDetails(id)
    console.log("🚀 ~ file: DpDetails.js ~ line 19 ~ fetchClientDpDetails ~ Dp", Dp)
    setDpDetails(Dp)
  }

  return (
    <>
      <div className="border back-forword-btn rounded-md mt-4">
        <div className="flex justify-between px-2 lg:px-2 py-4">
          <a href="" className="flex items-center justify-between">
            <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z" fill="#0075FC" />
            </svg>

            <span className="self-center text-xl font-bold whitespace-nowrap text-[#0075FC]  pl-3">
              DP Details
            </span>
          </a>
          <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex"></div>
        </div>
        <div className="flex justify-between pb-6 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                DP Id
              </div>
              <input disabled={Edit} defaultValue={DpDetails?.cm_dpid}
                type="text"
                placeholder="0000 0000 0000"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                DP Name
              </div>
              <input disabled={Edit} defaultValue={DpDetails?.dP_Id}
                type="text"
                placeholder="BPSBPL"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Account Name
              </div>
              <input disabled={Edit}
                type="text"
                placeholder="Saving"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Account Number
              </div>
              <input disabled={Edit} defaultValue={DpDetails?.dP_Id}
                type="text"
                placeholder="Write here"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                2nd Holder Name
              </div>
              <input disabled={Edit} type="text"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#E8E2E2] rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="ravi" />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                3rd Holder Name
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_ThirdHolderName}
                type="Text"
                placeholder="pooja"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Nominee
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_Id}
                type="text"
                placeholder="Ajay"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Nominee Guardian
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_NomineeGuardian}
                type="text"
                placeholder="Pooja"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                2nd Holder PAN
              </div>
              <input disabled={Edit} 
                defaultValue={DpDetails?.dP_ThirdHolderNamePAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                3rd Holder PAN
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_SecondHolderNamePAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>

            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Nominee PAN
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_NomineePAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                2nd Holder DOB
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_Id}
                type="Date"
                placeholder="@"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                3rd Holder DOB
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_Id}
                type="Date"
                placeholder="28/07/2022"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Nominee DOB
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_Id}
                type="Date"
                placeholder="28/07/2022"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Nominee Guardian DOB
              </div>
              <input disabled={Edit}
                type="Date"
                value="2001/08/02"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Status
              </div>
              <select disabled={FamilyRestroomRounded}
                // defaultValue={ClientData?.dP_Id}
                id="countries"
                class="bg-gray-50 border border-[#E8E2E2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option selected>Active</option>
                <option value="US">UnActive</option>
                <option value="CA">Other</option>
              </select>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                POA Date
              </div>
              <input disabled={Edit}
                type="Date"
                placeholder="28/07/2022"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>

            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                POA Holder
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_POAHolder}
                type="text"
                placeholder="Ravi"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                POA PAN
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_POAPAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                DOB POA
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_NomineeGuardianPAN}
                type="Date"
                value="2001/08/02"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>

            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                1st Holder Guardian
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_FirstHolderGuardian}
                type="text"
                placeholder="Rahul"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                2nd Holder Guardian
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_SecondHolderGuardian}
                type="text"
                placeholder="Aman"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                3rd Holder Guardian
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_ThirdHolderGuardian}
                type="text"
                placeholder="Rahul"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                1st Holder Guardian PAN
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_FirstHolderGuardianPAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                2nd Holder Guardian PAN
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_SecondHolderGuardianPAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                3rd Holder Guardian PAN
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_ThirdHolderGuardianPAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Nominee Guardian PAN
              </div>
              <input disabled={Edit}
                // defaultValue={ClientData?.dP_NomineeGuardianPAN}
                type="text"
                placeholder=""
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                1st holder Guardian DOB
              </div>
              <input disabled={Edit}
                type="Date"
                value="2001/08/02"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                2nd holder Guardian  DOB
              </div>
              <input disabled={Edit}
                type="Date"
                value="2001/08/02"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                3rd holder Guardian DOB
              </div>
              <input disabled={Edit}
                type="Date"
                value="2001/08/02"
                className="bg-gray-50 border border-[#E8E2E2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DpDetails