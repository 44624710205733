import React, { useState } from "react";
import Coman from "./Coman";

function BasicDetils() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  return (
    <>
      <div className="md:flex lg:flex xl:flex flex-wrap justify-between px-2 lg:px-2 py-4">
        <a href="" className="flex items-center">
          <svg
            width="30"
            height="12"
            viewBox="0 0 30 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6C0 8.20914 1.79086 10 4 10C6.20914 10 8 8.20914 8 6C8 3.79086 6.20914 2 4 2C1.79086 2 0 3.79086 0 6ZM29.5303 6.53033C29.8232 6.23744 29.8232 5.76256 29.5303 5.46967L24.7574 0.696699C24.4645 0.403806 23.9896 0.403806 23.6967 0.696699C23.4038 0.989593 23.4038 1.46447 23.6967 1.75736L27.9393 6L23.6967 10.2426C23.4038 10.5355 23.4038 11.0104 23.6967 11.3033C23.9896 11.5962 24.4645 11.5962 24.7574 11.3033L29.5303 6.53033ZM4 6.75H29V5.25H4V6.75Z"
              fill="#AFB5B7"
            />
          </svg>

          <span className="self-center md:text-xl lg:text-xl xl:text-xl text-sm font-bold whitespace-nowrap text-[#AFB5B7]  pl-3">
            Client Basic Details
          </span>
        </a>
        <div className="font-medium text-xl text-end text-[#383838] cursor-pointer flex md:mt-0 lg:mt-0 xl:mt-0 mt-3">
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            onClick={handleShow}
          >
            Dropdown button{" "}
            <svg
              class="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <div
        className="border bg-[#FFFFFF] back-forword-btn rounded-md  px-2 py-2"
        show={show}
      >
        <div className="flex wraapeer gap-between justify-between pb-6 ">
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Risk Category
              </div>
              <select
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              >
                <option selected>As Per your Data</option>
                <option value="US">As Per your Time</option>
                <option value="CA">Other</option>
              </select>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Client Name
              </div>
              <input
                type="text"
                placeholder="0000 0000 0000"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                First Name
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Middle Name
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#3D3D3D] lg:text-sm text-xs">
                Last Name
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Client Code
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                DOB / Registration
              </div>
              <div class="datepicker relative form-floating mb-3 w-full mt-1">
                <input
                  type="date"
                  class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-[#F5F2F2] rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
              </div>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                PAN No.
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Email
              </div>
              <input
                type="text"
                placeholder="@"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Mobile No.
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1 justify-between">
              <div className=" font-medium w-1/2 text-[#383838] lg:text-sm text-xs">
                Gender
              </div>
              <select
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[72%] p-2.5   "
              >
                <option selected>Male</option>
                <option value="US">Female</option>
                <option value="CA">Other</option>
              </select>
              <div className=" font-medium w-1/2 text-[#383838] lg:text-sm text-xs pl-6">
                <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 "
                  />
                  <label
                    for="checkbox-item-11"
                    class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                  >
                    {" "}
                    Special Category
                  </label>
                </div>
              </div>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                CIN Regi. No.
              </div>
              <input
                type="text"
                placeholder="Type CIN no."
                className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
              />
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                Client Status
              </div>
              <select
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option value="US" selected>
                  Active
                </option>
                <option value="CA">Inactive</option>
              </select>
            </div>
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium md:w-2/5 lg:w-2/5 xl:w-2/5 text-[#383838] lg:text-sm text-xs">
                Residential Status
              </div>
              <select
                id="countries"
                class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
              >
                <option selected>Select status</option>
                <option value="US">F1</option>
                <option value="CA">F2</option>
                <option value="CA">F3</option>
              </select>
            </div>
          </div>
          <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 lg:px-4 px-4">
            <div className="flex wraapeer gap-between items-center mt-1">
              <div className=" font-medium justify-start w-2/5 lg:text-sm text-xs text-[#3D3D3D]">
                Address
              </div>

                <input
                  type="text"

                  className="bg-gray-50 border border-[#F5F2F2] h-[92px] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
                />
              </div>
              <div className=" wraapeer items-center mt-1 grid grid-cols-2 ">
                <div className="flex wraapeer items-center mt-1 ml-2">
                  <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                    City
                  </div>
                  <input

                    type="text"
                    className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                  />
                </div>

                  <div className="flex wraapeer items-center mt-1 ml-2">
                    <div className=" font-medium  text-[#3D3D3D] lg:text-sm text-xs mr-2">
                      PinCode
                    </div>
                    <input

                      type="text"
                      className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                    />
                  </div>
                </div>

            {/* <div className=" items-center mt-1 grid grid-cols-2 ">
              <div className="flex justify-between items-center">
                <label className=" font-medium   text-[#383838] lg:text-sm text-xs">
                  State
                </label>
                <input 
                  defaultValue={pincode?.State}
>>>>>>> Stashed changes
                  type="text"
                  className=" bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500  w-full p-2.5   "
                />
              </div>
              <div className="flex wraapeer items-center mt-1">
                <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                  Introducer
                </div>
                <input
                  type="text"

<<<<<<< Updated upstream
                  className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
                />
              </div>
              <div className="flex wraapeer items-center mt-1 justify-between">
                <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                  RM
                </div>
                <select
                  id="countries"
                  class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
                >
                  <option selected>Select status</option>
                  <option value="US">F1</option>
                  <option value="CA">F2</option>
                  <option value="CA">F3</option>
                </select>
              </div>

              <div className="flex wraapeer items-center mt-1 justify-between">
                <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                  Occupation
                </div>
                <select
                  id="countries"
                  class="bg-gray-50 border border-[#F5F2F2] text-[#383838] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100%] p-2.5   "
                >
                  <option selected>Select status</option>
                  <option value="US">F1</option>
                  <option value="CA">F2</option>
                  <option value="CA">F3</option>
                </select>
              </div>
              <div className="flex wraapeer items-center mt-1">
                <div className=" font-medium w-2/5 text-[#383838] lg:text-sm text-xs">
                  Place of Incorporation
                </div>
                <input

                  type="text"
                  className="bg-gray-50 border border-[#F5F2F2] text-[#AFB5B7] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
                />
              </div>

                      <div id="dropdown" class="z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 block" data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(318.4px, 70.4px, 0px);">
                          <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                            <li>
                              <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                           </li>
                           <li>
               <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
              </li>
              <li>
                 <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
               </li>
              <li>
                 <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
              </li>
            </ul>                     </div> */}


          </div>
          </div>
          </div>
    </>
  );
}

export default BasicDetils;
