// const LogoImg = require('../img/honor_logo.png');
import LogoImg from "../img/honor_logo.png";

import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ProfileDropDown from "./ProfileDropDown";
import SidebarData from "./SidebarData";
import { Link, useLocation } from "react-router-dom";
import SingleListItem from "./SingleListItem";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import PopoverItem from "./Pophover";
import { useMediaQuery } from "react-responsive";
import LoaderHandler from "./LoaderHandler";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import zIndex from "@mui/material/styles/zIndex";
const drawerWidth = 240;
const closedWidth = 0;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isTablet }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: open ? drawerWidth : closedWidth,
  width: `calc(100% - ${open ? (isTablet ? "" : drawerWidth) : closedWidth}px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isTablet }) => ({
  width: drawerWidth,
  zIndex: `${isTablet && "999999999"}`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      ...{
        boxShadow: "0px 0px 4px 0px #ccc",
        borderRadius: "0% 0% 0% 0% ",
      },
    },
  }),
  ...(!open && {
    width: closedWidth,
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ListTag = styled(
  ListItemText,
  {}
)(({ theme }) => ({
  fontSize: "14px",
}));

const useStyle = makeStyles({
  toolbar: {
    padding: "0px 10px !important",
  },
  iconImg: {
    width: "25px",
  },
});

export default function Layout(props) {
  const [open, setOpen] = React.useState(true);
  const [pageTitle, setPageTitle] = React.useState(props.pageTitle);
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth < 720 ? true : false
  );
  const location = useLocation();
  const classes = useStyle();
  const [width, setScreenWidth] = React.useState(window.screen.width);
  const theme = useTheme();
  const [activeDropDown, setActiveDropDown] = React.useState("");

  const handleDropDown = (index) => {
    if (activeDropDown !== "" && parseInt(activeDropDown) == parseInt(index)) {
      setActiveDropDown("");
      return;
    }
    setActiveDropDown(index);
  };

  let isTablet = useMediaQuery({
    query: "(max-width:768px)",
  });

  useEffect(() => {
    document.getElementById("sidebar").style.position = isTablet
      ? "absolute"
      : "unset";
    document.getElementById("sidebar").style.display = isTablet
      ? "none"
      : "block ";

    isTablet && setOpen(false);
  }, [isTablet]);

  React.useEffect(() => {
    let tt = "";
    // switch (location.pathname) {
    //   case "/reports":
    //     tt = "Reports";
    //     break;
    //   case "/Home":
    //     tt = "Dashboard";
    //     break;
    //   case "/admin-console":
    //     tt = "Admin Console";
    //     break;
    //   case "/logic-defination":
    //     tt = "Logic Definition";
    //     break;
    //   case "/data-uploads":
    //     tt = "Data Uploads";
    //     break;
    //   case "/report-to-exchange":
    //     tt = "Report To Exchange";
    //     break;
    //   case "/report-to-fiu":
    //     tt = "Report To FIU";
    //     break;
    //   case "/settings":
    //     tt = "settings";
    //     break;
    //   default:
    //     tt = "Dashboard";
    //     break;
    // }
    setPageTitle(props.pageTitle);
    console.log(props.pageTitle);
    document.title = "Admin Console : : " + props.pageTitle;
  }, [location, props.pageTitle]);

  const handleDrawerOpen = () => {
    document.getElementById("sidebar").style.display = "block";

    setOpen(true);
  };

  const handleDrawerClose = () => {
    document.getElementById("sidebar").style.display = isTablet
      ? "none"
      : "block";

    setOpen(false);
  };
  const toggleDrawer = () => {
    // setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ boxShadow: "none", border: "none", background: "#fff" }}
        className="w-100 ml-0 "
        isTablet={isTablet}
        style={{ zIndex: 4 }}
      >
        <Toolbar class="flex justify-between">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              marginLeft: 0,
              ...(open && { display: "none" }),
            }}
            className="m-0 ml-0 "
          >
            <h6 className="text-[14px] ">
              <b>
                <img src={LogoImg} width="180px" />
              </b>
              {/* {isTablet && (
                <span>
                  <ChevronRightIcon />
                </span>
              )} */}
            </h6>
          </IconButton>

          <div
            className={`flex justify-between w-full p-2 items-center ${
              open && isTablet && "dull"
            }`}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: "#323232", fontWeight: "600", ml: "25px" }}
            >
              {pageTitle}
            </Typography>

            <div className="drop-section flex items-center">
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only "
              >
                Search
              </label>
              {/* <div className="relative">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block p-3 pl-10 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 "
                  placeholder="Search Mockups, Logos..."
                  required=""
                />
              </div> */}
              {/* <div className="notification-icon mx-2">
                <button
                  type="button"
                  className="text-blue-700 border border-gray-300 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 
                        relative
                        after:content-[''] after:absolute after:w-[10px] after:h-[10px] after:bg-[red] after:rounded-full after:top-[9px]
                        after:right-[12px]
                        "
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>
              </div> */}
              <div class="md:block hidden">
                {" "}
                <ProfileDropDown />
              </div>
              <div class="md:hidden block">
                <button
                  type="button"
                  className="text-blue-700 border border-gray-300 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 
                        "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        open={open}
        id="sidebar"
        isTablet={isTablet}
        style={{ zIndex: 10 }}
      >
        <DrawerHeader className="flex justify-between" style={{ zIndex: "1" }}>
          <div
            className="text-[24px] w-full font-[700] "
            // onClick={handleDrawerClose}
          >
            {theme.direction === "ltr" ? (
              <div className="flex justify-between items-center mt-[15px]">
                <div>
                  <img src={LogoImg} width="180px" />
                </div>
                <ChevronLeftIcon />
              </div>
            ) : (
              ""
            )}
          </div>
        </DrawerHeader>
        <List className="pt-0">
          {SidebarData.map((item, index) => {
            return (
              <>
                {open ? (
                  <SingleListItem
                    singleList={item}
                    handleDropDown={handleDropDown}
                    classes={classes}
                    activeDropDown={activeDropDown}
                    open={open}
                    index={index}
                    activePath={location.pathname}
                    handleDrawerClose={handleDrawerClose}
                  />
                ) : (
                  <PopoverItem
                    singleList={item}
                    handleDropDown={handleDropDown}
                    classes={classes}
                    activeDropDown={activeDropDown}
                    open={open}
                    index={index}
                    activePath={location.pathname}
                    isTablet={isTablet}
                  />
                )}
              </>
            );
          })}
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          height: "100vh",
          background: "#fcfbfc",
          maxWidth: `calc(100% - ${open ? closedWidth : ""}px)`,
          overflowX: "auto",
          zIndex: "10",
          marginTop: "38px",
        }}
        className={open && isTablet && `${"dull"}`}
      >
        {/* <DrawerHeader /> */}
        {props.children}
        <LoaderHandler />
      </Box>
    </Box>
  );
}
