import { Modal, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";

import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import imageCompression from "browser-image-compression";

import "react-image-crop/dist/ReactCrop.css";

const imageOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const UploadImg = ({
  isModalOpen,
  setIsModalOpen,
  thumbnail,
  setFinalThumbnail,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [aspect, setAspect] = useState(16 / 9);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finalImage, setFinalImage] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    onDownloadCropClick();
  };
  const handleCancel = () => {
    setImgSrc(null);
    setUploadFile(null);
    setIsModalOpen(false);
  };

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    // setLoading(true);
    return centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: 200,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  useEffect(() => {
    // setCrop(centerAspectCrop(100, 100, aspect));
    setUploadFile(thumbnail);
    onSelectFile(thumbnail);
  }, [thumbnail]);

  function onSelectFile(file) {
    // setLoading(true);
    if (file) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(file);
      // setLoading(false);
    } else {
      // setLoading(false);
    }
  }

  function onImageLoad(e) {
    // setLoading(true);
    if (aspect) {
      const { width, height } = e.currentTarget;
      // console.log({ width, height });
      setCrop(centerAspectCrop(width, height, aspect));
      // setLoading(false);
    } else {
      // setLoading(false);
    }
  }

  async function onDownloadCropClick() {
    setLoading(true);
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    const blobWithCustomName = new Blob([blob], { type: "image/png" });
    blobWithCustomName.name = uploadFile?.name;

    // setFinalThumbnail(blobWithCustomName);

    const compressedFile = await imageCompression(blob, imageOptions);

    setFinalThumbnail(compressedFile);
    setUploadFile(null);
    setImgSrc(null);
    setCrop(null);
    setCompletedCrop(null);
    setLoading(false);
    setIsModalOpen(false);
  }
  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      // We use canvasPreview as it's much faster than imgPreview.
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
    }
  }, [completedCrop, scale, rotate]);

  // console.log({ crop });
  // console.log({ completedCrop });

  return (
    <>
      <Modal
        title="Upload Image"
        className="w-[100%]"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={true}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <div>
          {loading ? (
            <div className="flex justify-center items-center">
              <Spin size="large" className="">
                <h1 className="mt-20 text-blue-900">Loading...</h1>
              </Spin>
            </div>
          ) : (
            <>
              <div className="mt-3">
                {!!imgSrc && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                )}
              </div>
              <div className="mt-3 hidden">
                {!!completedCrop && (
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default UploadImg;
