import React, { useState } from "react";
import ReportTable from "../reports/ReportTable";
import { GetCall, PostCall } from "../../service/Services";
import axiosInstance from "../../service/ApiCall";
import "./style.css";
import DataUploadTable from "./DataUploadTable";
import AddDocumentType from "../../components/AddDocumentType";

export default function DataUpload() {
  const [isLoading, setIsLocation] = React.useState(false);
  const [reportsData, setReportsData] = React.useState([]);
  const [model, setmodel] = useState(false);
  const [dataFrom, setDateFrom] = React.useState("7-10-2022");
  const [dataTo, setDateTo] = React.useState("7-13-2022");
  const [alertType, setAlertType] = React.useState("BSE");
  const [clientID, setClientID] = React.useState("TCRT636");

  const FetchData = async () => {
  
  };

  React.useEffect(() => {
    setIsLocation(true);
    FetchData();
    setIsLocation(false);
  }, [dataFrom, dataTo, alertType, clientID]);

  return (
    <>
      <div className="rounded-sm bg-white shadow-lg  p-2 justify-between flex items-center flex-wrap margin-style">
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            Date
          </label>
          <input
            name="start"
            type="date"
            className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[120px]  p-2.5  drop-shadow-md"
            placeholder="07/22/2019"
            value={dataFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            Issuer
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>BSE</option>
            <option>NSE</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            Segment
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>BSE</option>
            <option>NSE</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            FTP
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>BSE</option>
            <option>NSE</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            import
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>BSE</option>
            <option>NSE</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 ">
          <button
            onClick={() => setmodel(true)}
            type="button"
            className="text-white w-[70px] bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 z-10"
          >
            GO
          </button>
        </div>
      </div>
      <div className="">
        <DataUploadTable reportData={reportsData} />
      </div>
    </>
  );
}
