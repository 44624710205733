import React, { useState } from 'react'
import PopupModel from './PopupModel'

const AddDocumentType = (props) => {

    const [check, setcheck] = useState(false)
    
  return (
    <div>
        <PopupModel open={props.open} close={props.setmodel} header={'Add New Document Type'}>
        <hr className='my-4'></hr>
        <div className='w-full'>
            <div className='flex flex-row sm:flex-wrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap md:flex-nowrap flex-wrap'>
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900  xl:w-[170px] 2xl:w-[170px] lg:w-[170px] md:w-[120px] flex items-center" >Roll name</label>
                <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block xl:w-[500px] 2xl:w-[500px] lg:w-[500px] md:w-[360px] sm:w-[250px] w-[207px] p-2.5 " placeholder="John" required/>
            </div>
            <div className='flex flex-row sm:flex-wrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap md:flex-nowrap flex-wrap mt-4 '>
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900  xl:w-[170px] 2xl:w-[170px] lg:w-[170px] md:w-[120px] flex items-center" >Document Type</label>
            <div>
                <button onClick={() => setcheck(!check)} id="dropdownDefault" data-dropdown-toggle="dropdown" class="flex justify-between p-[10px] relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block xl:w-[450px] 2xl:w-[450px] lg:w-[450px] md:w-[360px] sm:w-[250px] w-[207px]" type="button">Dropdown button <svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                    {check&&<div id="dropdown" class="xl:w-[450px] 2xl:w-[450px] lg:w-[450px] md:w-[360px] sm:w-[250px] w-[207px] absolute z-10 bg-white border rounded " data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom" >
                        <ul class="py-1 text-sm" aria-labelledby="dropdownDefault">
                          <li>
                            <a href="#" class="block py-2 px-4 text-gray-900">Dashboard</a>
                          </li>
                          <li>
                            <a href="#" class="block py-2 px-4 ">Settings</a>
                          </li>
                          <li>
                            <a href="#" class="block py-2 px-4">Earnings</a>
                          </li>
                          <li>
                            <a href="#" class="block py-2 px-4">Sign out</a>
                          </li>
                        </ul>
                    </div>}
            </div>
         
            </div>
            <div className='flex flex-row sm:flex-wrap lg:flex-nowrap	xl:flex-nowrap 2xl:flex-nowrap md:flex-nowrap flex-wrap mt-4 '>
                <label for="first_name" class=" sm:w-[100%] w-[100%] block mb-2 text-sm font-medium text-gray-900  xl:w-[170px] 2xl:w-[170px] lg:w-[170px] md:w-[120px] flex items-center" >Add Permissions</label>
                <div className="flex flex-row  sm:ml-1 md:m-0 lg:m-0 xl:m-0  sm:flex-wrap lg:flex-nowrap	xl:flex-nowrap 2xl:flex-nowrap md:flex-nowrap flex-wrap xl:w-[500px] 2xl:w-[500px] lg:w-[500px] md:w-[360px] sm:w-[250px] w-[120px] justify-between	">
                    <div className=" flex flex-row mr-2 text-center ">
                      <input type="checkbox" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[15px] p-2.5 mr-2" placeholder="Select document type" required />
                      <label className="text-[0.9rem] flex items-center ">View</label>  
                    </div> 

                <div className="flex flex-row mr-2 text-center">
                <input type="checkbox" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[15px] p-2.5 mr-2" placeholder="Select document type" required />
              <label className="text-[0.9rem] flex items-center">
              Add
                </label>  </div> 

                <div className=" flex flex-row mr-2 text-center">
                <input type="checkbox" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[15px] p-2.5 mr-2" placeholder="Select document type" required />
              <label className="text-[0.9rem] flex items-center">
              Edit
                </label>  </div> 

                <div  className=" flex flex-row mr-2 text-center" >
                <input type="checkbox" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[15px] p-2.5 mr-2" placeholder="Select document type" required />
              <label className="text-[0.9rem] flex items-center">
              Delete
                </label>  </div> 

                <div  className=" flex flex-row mr-2 text-center" >
                <input type="checkbox" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[15px] p-2.5 mr-2" placeholder="Select document type" required />
              <label className="text-[0.9rem] flex items-center">
              Print
                </label>  </div> 

                <div  className=" flex flex-row mr-2 text-center" >
                <input type="checkbox" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[15px] p-2.5 mr-2" placeholder="Select document type" required />
              <label className="text-[0.9rem] flex items-center">
              Export
                </label>  </div>
                </div>
            </div>
            <div className='text-center flex-wrap mt-5'>
              <button class="mt-4 mr-4 px-7 py-3 border-blue-600 text-blue-600 text-sm uppercase rounded w-[175px] border">cancel</button>
              <button class="mt-4 mr-4 px-7 py-3 border-blue-600 text-white text-sm uppercase rounded w-[175px] border bg-blue-600 ">Add</button>
            </div>
        </div>
        
        </PopupModel>
        </div>
  )
}

export default AddDocumentType