import React ,{ useEffect,useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  p: 3,
};

export default function PopupModel(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false);props.close(false)}

  useEffect(() => {
props.open&&setOpen(true)
  }, [props.open])
  

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  close
      
      ><Box  sx={style} className="sm:w-[300px] md:w-[530px] lg:w-[80%] xl:w-[80%] 2xl:w-[80%] lg:h-[350px]"> 
      <div className='flex items-center justify-between'>
        <div>
          {props.header}</div><svg xmlns="http://www.w3.org/2000/svg" onClick={handleClose} width="16" height="16" fill="currentColor" class="bi bi-x-lg cursor-pointer" viewBox="0 0 16 16">
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
      </div>
        {props.children}</Box>
      </Modal>
    </div>
  );
}
