import axios from "axios";
// import { BACKEND_BASE_URL } from "./baseurl";
// const BACKEND_URL = "http://secmark.centralindia.cloudapp.azure.com/";
// const BACKEND_URL = "http://secmark.centralindia.cloudapp.azure.com/";
const BACKEND_URL = "https://clientdataheader.azurewebsites.net/Home/";
const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // const auth = localStorage.getItem("token");
    // if (auth) {
    //   config.headers = {
    //     Authorization: `Bearer ${auth}`,
    //   };
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default axiosInstance;
