import { Modal, Spin } from "antd";
import React, { useState } from "react";
import { BASE_API } from "../../helper/Api";
import axios from "axios";

const UploadImages = ({
  isModalOpen,
  setIsModalOpen,
  obituaryId,
  fetchData,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    handleUpload();

    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);

  const handleThumbnailChange = (event) => {
    const file = event.target.files;
    // setThumbnail(file);
    for (let index = 0; index < file.length; index++) {
      const element = file[index];
      setImagesArray((prev) => [...prev, element]);
    }
    // setImagesArray((prev) => [...prev, file]);
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setVideo(file);
  };

  const handleUpload = async () => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();
      for (let i = 0; i < imagesArray.length; i++) {
        formData.append("add_img", imagesArray[i]);
      }

      const response = await axios.post(
        BASE_API + `api/upload-image-admin/${obituaryId}`,
        formData,
        {
          headers: headers,
        }
      );

      if (response.data.status) {
        fetchData();
        setIsModalOpen(false);
      } else {
        console.log("error", response.data.error);
      }
    } catch (error) {
      console.log("error", error.message);
    }

    // You can implement the file upload logic to the server here.
    // For simplicity, let's just log the selected files for now.
    console.log("Thumbnail:", thumbnail);
    console.log("Video:", video);

    // TODO: Implement file upload to the server using a library like axios or fetch.
    // Example:
    // const formData = new FormData();
    // formData.append('thumbnail', thumbnail);
    // formData.append('video', video);
    // axios.post('/api/upload', formData)
    //   .then(response => {
    //     // Handle successful upload
    //   })
    //   .catch(error => {
    //     // Handle upload error
    //   });
  };

  return (
    <>
      <Modal
        title="Upload Images"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
        closable={false}
        maskClosable={false}
      >
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : (
          <div class="mb-3">
            <label
              for="formFileMultiple"
              class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
            >
              Images
            </label>
            <input
              class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
              type="file"
              accept="image/*"
              onChange={handleThumbnailChange}
              id="formFileMultiple"
              multiple
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default UploadImages;
