// export const BASE_API = "http://localhost:3002/";
export const BASE_API = "https://www.obituary.api.techcarrel.in/";
// "https://www.obituary.api.techcarrel.in/";
// "http://localhost:3002/"
// export async function CallPOSTFORMAPI(url, params = [], headers = {}) {
//   try {
//     let token = localStorage.getItem("callflow_token");
//     let AllHeaders = { ...headers };
//     if (token) {
//       AllHeaders = { Authorization: token, ...headers };
//     }

//     let res = await fetch(BASE_API + url, {
//       method: "POST",
//       headers: { "Content-Type": "multipart/form-data", ...AllHeaders },
//       body: params,
//     });

//     if (!res.ok) {
//       return { status: false, msg: "No Data Found", data: [] };
//     }
//     let resultData = await res.json();
//     return { status: true, msg: "", data: resultData };
//   } catch (e) {
//     return { status: false, msg: e.message, data: [] };
//   }
// }

// =====================
export const CallGetApi = async (url, params = [], headers = {}) => {
  try {
    let token = localStorage.getItem("obituary_token");
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: token, ...headers };
    }

    const response = await fetch.get(BASE_API + url, {
      method: "GET",
      headers: { ...AllHeaders },
    });
    return response;
  } catch (error) {
    return { status: false, msg: error.message, data: [] };
  }
};

export async function CallPOSTAPI(url, params = [], headers = {}) {
  try {
    let token = localStorage.getItem("obituary_token");
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: token, ...headers };
    }

    let res = await fetch(BASE_API + url, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(params),
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}
