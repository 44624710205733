import { Modal, Spin } from "antd";
import React, { useState } from "react";
import { BASE_API } from "../../helper/Api";
import axios from "axios";

const UploadThumb = ({
  isModalOpen,
  setIsModalOpen,
  fetchData,
  obituaryId,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    // setLoading(true);
    handleUpload();
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    setThumbnail(file);
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setVideo(file);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();
      formData.append("thumbnail", thumbnail);

      const response = await axios.post(
        BASE_API + `api/update-thumbnail/${obituaryId}`,
        formData,
        {
          headers: headers,
        }
      );

      if (response.data.status) {
        fetchData();
        setIsModalOpen(false);
        setLoading(false);
      } else {
        console.log("error", response.data.error);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error.message);
    }
  };

  return (
    <>
      <Modal
        title="Upload Thumbnail"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
        closable={false}
        maskClosable={false}
      >
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : (
          <div class="mb-3">
            <label
              for="formFileMultiple"
              class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
            >
              Thumbnail
            </label>
            <input
              class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
              type="file"
              accept="image/*"
              onChange={handleThumbnailChange}
              id="formFileMultiple"
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default UploadThumb;
