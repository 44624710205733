import React, { useState, useEffect } from 'react'
import Aml from './Aml';
import BankDetail from './BankDetail';
import Coman from './coman';
import KycDetails from './KycDetails';
import { clientBankDetails, GetClintList,clientMasterDetails } from "../../service/Services";

import Others from './Others';
import {  useParams, useLocation} from 'react-router-dom';
import ClientMasterTable from './ClientMasterTable';




function ClientMaster() {
  const [ClientData, setClientData] = React.useState([]);
  const [clientId,setClitId]        = React.useState('a');

  const {id}                        = useParams()
  const [tabs, settabs]             = useState('')
  const [Edit, setEdit]             = useState(true)
  const location                    = useLocation()




useEffect(()=>{ id && fetchClientBankDetails(); },[id])

useEffect(()=>{
  if(location.search.includes("edit")) {
    setEdit(false) ;
    settabs("KYC Details")
  } 
  id ? settabs("KYC Details"):settabs("")
},[id])

useEffect(()=>{
  if(location.search.includes("edit")) {
    setEdit(false) ;
    settabs("KYC Details")
  } 
  id ? settabs("KYC Details"):settabs("")
},[id])


  const fetchClientBankDetails = async () => {
    const data  = await clientBankDetails(id)
    // const data2 = await GetClintList(id);


    const GetClimaster = await clientMasterDetails('api/Masters/ClientTpDetails?clientId=',id);

    console.log('============= here we are======================')
    console.log(GetClimaster);
    if(GetClimaster.status){
      // console.log(GetClimaster.result.A[0])
      setClientData(GetClimaster.result.A[0]);


    }

    settabs("KYC Details")
  }

  // const FetchData = async () => {
  //   let res = await GetBankDetails('ClientBankDetails?ClientId='+clientId);
  //   if(res.status){
  //     console.log(res.result)
  //     setClientData(res.result)
  //     // console.log(res.result)

  //   }
  // };
  // useEffect(() => {
  //   setIsLocation(true);
  //   FetchData() 
  // },[]);

  return (
    <>
     {tabs === '' ? <ClientMasterTable setEdit={setEdit} settabs={settabs} />:
     <>
      <Coman ClientData={ClientData} id={id}/>
      <ul class="md:flex lg:flex xl:flex md:w-[100%] lg:w-[100%] xl:w-[100%] list-none border-b-0 pl-0 mb-4 " id="tab-client">
        <li class="md:w-[25%] lg:w-[25%] xl:w-[25%] -mb-px mr-2 last:mr-0 flex-auto text-center">
          <div aria-current="page" class={`w-[100%] inline-block py-4 px-18 text-sm font-medium text-center  ${tabs == "KYC Details" ? 'inline-block py-4 px-18 text-sm font-medium text-center text-white bg-blue-600 rounded-md' : "text-gray-500 "}  `} id="kyc-Details" onClick={() => {
            settabs('KYC Details')
          }} >KYC Details</div>
        </li>
        <li class="md:w-[25%] lg:w-[25%] xl:w-[25%] -mb-px mr-2 last:mr-0 flex-auto text-center">
          <div class={`w-[100%] inline-block py-4 px-18 text-sm font-medium text-center  ${tabs == "Bank and DP Details" ? 'inline-block py-4 px-18 text-sm font-medium text-center text-white bg-blue-600 rounded-md' : "text-gray-500 "}  `} id="bank-details" onClick={() => {
            settabs('Bank and DP Details')
          }}>Bank and DP Details</div>
        </li>
        <li class="md:w-[25%] lg:w-[25%] xl:w-[25%] -mb-px mr-2 last:mr-0 flex-auto text-center">
          <div class={`w-[100%] inline-block py-4 px-18 text-sm font-medium text-center  ${tabs == "AML" ? 'inline-block py-4 px-18 text-sm font-medium text-center text-white bg-blue-600 rounded-md' : "text-gray-500 "}  `} id='aml' onClick={() => {
            settabs('AML')
          }}>AML</div>
        </li>
        <li class="md:w-[25%] lg:w-[25%] xl:w-[25%] -mb-px mr-2 last:mr-0 flex-auto text-center">
          <div class={`w-[100%] inline-block py-4 px-18 text-sm font-medium text-center  ${tabs == "Others" ? 'inline-block py-4 px-18 text-sm font-medium text-center text-white bg-blue-600 rounded-md' : "text-gray-500 "}  `} id='other' onClick={() => {
            settabs('Others')
          }}>Others</div>
        </li>

      </ul>
      {
        tabs == 'KYC Details' && <KycDetails ClientData={ClientData} Edit={Edit} id={id}/>
      }
      {
        tabs == 'Bank and DP Details' && <BankDetail ClientData={ClientData}  Edit={Edit} id={id}/>
      }
      {
        tabs == 'AML' && <Aml ClientData={ClientData}  Edit={Edit} id={id}/>
      }
      {
        tabs == 'Others' && <Others  ClientData={ClientData} Edit={Edit} id={id}/>
      }
      
      </>
      }
    </>
  )
}

export default ClientMaster;