import React, { useEffect, useState } from "react";
import ReportTable from "../reports/ReportTable";
import { GetCall, PostCall } from "../../service/Services";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../service/ApiCall";
import "./style.css";
import ObituaryTable from "./ObituaryTable ";
import AddDocumentType from "../../components/AddDocumentType";
import { Spin, Tabs } from "antd";
import axios from "axios";
import { BASE_API } from "../../helper/Api";

export default function ObituaryDeatils() {
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);

  const [publishedObituaryData, setPublishedObituaryData] = useState([]);
  const [unpublishedObituaryData, setUnpublishedObituaryData] = useState([]);
  const [dataFrom, setDateFrom] = React.useState("7-10-2022");
  const [dataTo, setDateTo] = React.useState("7-13-2022");
  const [alertType, setAlertType] = React.useState("BSE");
  const [clientID, setClientID] = React.useState("TCRT636");

  const fetchData = async () => {
    try {
      // Make a GET request to your API endpoint
      const response = await axios.get(BASE_API + "api/obituary_data");
      const ar = [];
      for (let index = 0; index < response.data.data.length; index++) {
        const element = response.data.data[index];
        const obj = {
          // index: index + 1,
          ...element,
        };
        ar.push(obj);
      }
      let publishedData = ar
        .filter((item) => item.published === 1)
        .map((item, i) => ({ index: i + 1, ...item }));
      let unpublishedData = ar
        .filter((item) => item.published === 0)
        .map((item, i) => ({ index: i + 1, ...item }));
      // setData(currentData);
      setPublishedObituaryData(publishedData);
      setUnpublishedObituaryData(unpublishedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   fetchData();
  //   setLoading(false);
  // }, []);

  React.useEffect(() => {
    fetchData();
  }, [dataFrom, dataTo, alertType, clientID]);

  const items = [
    {
      key: "1",
      label: "Pending Obituaries",
      children: (
        <ObituaryTable
          dataType={0}
          data={unpublishedObituaryData}
          setData={setUnpublishedObituaryData}
          fetchData={() => fetchData()}
        />
      ),
    },
    {
      key: "2",
      label: "Published Obituaries",
      children: (
        <ObituaryTable
          dataType={1}
          data={publishedObituaryData}
          setData={setPublishedObituaryData}
          fetchData={() => fetchData()}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  // const handleTabChange = (data) => {
  //   console.log("Tab changed. Loading will be set to false.");
  //   setLoading(false);
  //   console.log("Loading state after tab change:", loading);
  // };

  // console.log({ state });

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin size="large" className="">
          <h1 className="mt-20 text-blue-900">Loading...</h1>
        </Spin>
      </div>
    );
  }

  return (
    <>
      <div className="">
        {/* <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            Date
          </label>
          <input
            name="start"
            type="date"
            className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[120px]  p-2.5  drop-shadow-md"
            placeholder="07/22/2019"
            value={dataFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            State
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>AL</option>
            <option>AI</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            FirstName
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>Adam</option>
            <option>Mick</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            LastName
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>Thomas</option>
            <option>Luther</option>
          </select>
        </div> */}
        <div className="relative flex items-center justify-end">
          <Link to="/obituary-create">
            <button
              // onClick={() => setmodel(true)}
              type="button"
              className="text-white w-[200px] bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 z-10"
            >
              Create Obituary
            </button>
          </Link>
        </div>
      </div>
      <div className="">
        <Tabs
          defaultActiveKey={state?.activeTab || "1"}
          items={items}
          // onChange={(activeKey) => handleTabChange(activeKey)}
        />
        {/* <ObituaryTable reportData={reportsData} /> */}
      </div>
    </>
  );
}
