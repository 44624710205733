import React from "react";
import ReportTable from "./ReportTable";
import { GetCall, PostCall } from "../../service/Services";
import axiosInstance from "../../service/ApiCall";
import {start_loader,stop_loader} from '../../redux/slice/loaderSlice';
import { useDispatch } from "react-redux";
import "./style.css"


export default function Reports() {
  const dispatch = useDispatch();
  const [isLoading, setIsLocation] = React.useState(false);
  const [reportsData, setReportsData] = React.useState([]);
  const [dataFrom, setDateFrom] = React.useState("2022-07-06");
  const [dataTo, setDateTo] = React.useState("2022-08-06");
  const [alertType, setAlertType] = React.useState("BSE");
  const [clientID, setClientID] = React.useState("");
  const [alertReport, setalertReport] = React.useState("TcRt123");

  

  const FetchData = async () => {
  
  };

  React.useEffect(() => {
    dispatch(start_loader());
      FetchData();
    dispatch(stop_loader());
  }, [dataFrom, dataTo, alertType, clientID]);

  return (
    <>
      <div className="rounded-sm bg-white shadow-lg justify-between flex items-center flex-wrap p-2 margin-style">
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            From Date
          </label>
          <input
            name="start"
            type="date"
            className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[120px]  p-2.5  drop-shadow-md"
            placeholder="07/22/2019"
            value={dataFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            To Date
          </label>
          <input
            name="start"
            type="date"
            className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[120px]  p-2.5  drop-shadow-md"
            placeholder="07/22/2019"
            value={dataTo}
            onChange={(e) => setDateTo(e.target.value)}
          />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            Status
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setalertReport(e.target.value)}
          >
            <option>Open</option>
            <option>Pending</option>
            <option>Asign</option>
            <option>Reported</option>
            <option>All</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label
            htmlFor=""
            className="text-[#0075FC] w-[70px] text-[12px]"
          >
            Client Code
          </label>
          <input
            type={"text "}
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            value={clientID}
            onChange={(e) => setClientID(e.target.value)}
          />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            Alert Type
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>Exchange</option>
            <option>DP</option>
            <option>Both</option>
          </select>

        </div>
        <div className="rounded-sm bg-white shadow-lg  p-2 justify-between w-full flex items-center flex-wrap ">
          {/* <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
          DP Alert  
          </label>
          <input
              type={"text "}
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="TCRT636"
              value={clientID}
              onChange={(e) => setClientID(e.target.value)}
            />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
          DP Alert type	  
          </label>
          <input
              type={"text "}
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="TCRT636"
              value={clientID}
              onChange={(e) => setClientID(e.target.value)}
            />
        </div> */}
          {/* <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[40px] text-[12px]">
         status
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setalertReport(e.target.value)}
          >
            <option>All</option>
            <option>Pending</option>
            <option>Asign</option>
          </select>
        </div> */}
          {/* <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
          Segment  
          </label>
          <input
              type={"text "}
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="TCRT636"
              value={clientID}
              onChange={(e) => setClientID(e.target.value)}
            />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
          Exchange 
          </label>
          <input
              type={"text "}
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="TCRT636"
              value={clientID}
              onChange={(e) => setClientID(e.target.value)}
            />
        </div> */}
        </div>
        {/* <div className="rounded-sm bg-white shadow-lg  p-2 justify-between w-full flex items-center flex-wrap">
        <div className="relative flex items-center ml-2 my-2 input-size">
            <label
              htmlFor=""
              className="text-[#0075FC] w-[70px] text-[12px]"
            >
              Scrip
            </label>
            <input
              type={"text "}
              className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="ANSDP350"
              value={clientID}
              onChange={(e) => setClientID(e.target.value)}
            />
          </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
          Alert Name  
          </label>
            <input
              type="search"
              class="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
          PAN   
          </label>
            <input
              type="search"
              class="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
          AML Id  
          </label>
            <input
              type="search"
              class="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
        </div>
    </div> */}
        <div style={{maxWidth:'100%',overflowX:'auto'}} >
          <ReportTable reportData={reportsData} />
        </div>
      </div>
    </>
  );
}
