import React, { useState } from 'react'
import AddDocumentType from "../../components/AddDocumentType";
function ManagerPermissions() {
    const [model, setmodel] = useState(false);

    return (
        <>
            <nav className="bg-white border-gray-200 py-2.5 rounded margin-style">
                <div className="container flex flex-wrap justify-between items-center mx-auto margin-style">
                    <a href="" className="flex items-center justify-between">
                        <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.7959 1.73977L1.81923 7.71643C1.1134 8.42227 1.1134 9.57727 1.81923 10.2831L7.7959 16.2598" stroke="#323232" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className="self-center text-xl font-medium whitespace-nowrap pl-3">Manager Permissions</span>
                    </a>
                </div>
            </nav>
            <div className='border back-forword-btn rounded-sm mt-3 margin-style'>

                <div class="overflow-x-auto relative">
                    <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50  py-12">
                            <tr>
                                <th scope="col" className="py-4 px-6 bg-[#EEEEEE] text-[#0075FC] text-[12px]">
                                    Role Name
                                </th>
                                <th scope="col" className="py-4 px-6 bg-[#EEEEEE] text-[#0075FC] text-[12px]">
                                    Document Type
                                </th>
                                <th scope="col" className="py-0 px-2 bg-[#EEEEEE] text-[#0075FC] ">
                                    <div className='flex   justify-between'>
                                        <div className=' lg:px-4 px-2 text-[12px]'>  Permissions</div>
                                        <div className=' lg:px-4 '>
                                            <div className='flex '>
                                                <div className='lg:px-4 px-2 text-[#AFB5B7] text-[10px] font-medium'>Last Modified : 12/07/2022</div>
                                                <div className=' lg:px-4 px-2 text-[#AFB5B7] border-r-[2px] border-[#A8A8A8]'> <div className="flex md:order-2 items-center justify-between">
                                                    <span className=" text-[10px] font-medium text-[#0075FC] pr-5">Active</span>
                                                    <label for="checked-toggle" class="inline-flex relative items-center cursor-pointer">
                                                        <input type="checkbox" value="" id="checked-toggle" class="sr-only peer" />
                                                        <div className="w-8 h-4 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[5px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-blue-600"></div>
                                                    </label>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th scope="col" className="py-0 px-2 bg-[#EEEEEE] text-[#0075FC] justify-center">
                                    <button type="button" class=" text-white bg-[#0075FC] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[10px] px-4 py-[12px]   focus:outline-none " onClick={() => setmodel(true)}>+Add Doc. Type</button>
                                    <AddDocumentType open={model} setmodel={setmodel} />

                                </th>
                                <th scope="col" className="py-4 px-6 bg-[#EEEEEE] text-[#0075FC] text-[12px] items-center">
                                    <button type="button" class=" text-[#FF5858] border-2 border-[#FF5858]  focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[10px] px-4 py-2 focus:outline-none">Delete Role</button>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b ">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                    Manager
                                </th>
                                <td class="py-4 px-6 text-left">
                                    Upload Document
                                </td>
                                <td class="py-4 px-6">
                                    <div className='flex  pb-2 '>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">View</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Add</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Edit</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Delete</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Print</label>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td class="py-4 px-6">
                                    <div className='lg:px-4 px-2'>
                                        <div className='flex items-center'>
                                            <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                            <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Export</label>
                                        </div>
                                    </div>
                                </td>
                                <td class="py-4 px-6">
                                    <div className='lg:px-4 px-2'>
                                        <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.0956 10.1468C22.3282 10.1468 22.5392 10.2483 22.706 10.4198C22.8615 10.603 22.9397 10.8305 22.9171 11.0708C22.9171 11.1501 22.2953 19.0134 21.9401 22.3232C21.7177 24.3543 20.4084 25.5875 18.4443 25.6213C16.9341 25.6552 15.4579 25.6668 14.0044 25.6668C12.4613 25.6668 10.9522 25.6552 9.48741 25.6213C7.58915 25.5758 6.27863 24.3205 6.06759 22.3232C5.70223 19.0017 5.09179 11.1501 5.08045 11.0708C5.0691 10.8305 5.14626 10.603 5.30284 10.4198C5.45715 10.2483 5.67954 10.1468 5.91328 10.1468H22.0956ZM16.4088 2.3335C17.4402 2.3335 18.3616 3.05332 18.6282 4.07998L18.8188 4.93164C18.9731 5.6258 19.5745 6.11696 20.2666 6.11696H23.6683C24.1222 6.11696 24.5 6.49379 24.5 6.97328V7.41661C24.5 7.88444 24.1222 8.27294 23.6683 8.27294H4.33283C3.87784 8.27294 3.5 7.88444 3.5 7.41661V6.97328C3.5 6.49379 3.87784 6.11696 4.33283 6.11696H7.73449C8.42549 6.11696 9.02685 5.6258 9.1823 4.9328L9.36044 4.13714C9.63729 3.05332 10.5484 2.3335 11.5911 2.3335H16.4088Z" fill="#FF5858" />
                                        </svg>

                                    </div>
                                </td>
                            </tr>
                            <tr class="bg-white border-b">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                </th>
                                <td class="py-4 px-6 text-left">
                                    Client Master
                                </td>
                                <td class="py-4 px-6">
                                    <div className='flex  pb-2 '>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">View</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Add</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Edit</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Delete</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Print</label>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td class="py-4 px-6">
                                    <div className='lg:px-4 px-2'>
                                        <div className='flex items-center'>
                                            <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                            <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Export</label>
                                        </div>
                                    </div>
                                </td>
                                <td class="py-4 px-6">
                                    <div className='lg:px-4 px-2'>
                                        <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.0956 10.1468C22.3282 10.1468 22.5392 10.2483 22.706 10.4198C22.8615 10.603 22.9397 10.8305 22.9171 11.0708C22.9171 11.1501 22.2953 19.0134 21.9401 22.3232C21.7177 24.3543 20.4084 25.5875 18.4443 25.6213C16.9341 25.6552 15.4579 25.6668 14.0044 25.6668C12.4613 25.6668 10.9522 25.6552 9.48741 25.6213C7.58915 25.5758 6.27863 24.3205 6.06759 22.3232C5.70223 19.0017 5.09179 11.1501 5.08045 11.0708C5.0691 10.8305 5.14626 10.603 5.30284 10.4198C5.45715 10.2483 5.67954 10.1468 5.91328 10.1468H22.0956ZM16.4088 2.3335C17.4402 2.3335 18.3616 3.05332 18.6282 4.07998L18.8188 4.93164C18.9731 5.6258 19.5745 6.11696 20.2666 6.11696H23.6683C24.1222 6.11696 24.5 6.49379 24.5 6.97328V7.41661C24.5 7.88444 24.1222 8.27294 23.6683 8.27294H4.33283C3.87784 8.27294 3.5 7.88444 3.5 7.41661V6.97328C3.5 6.49379 3.87784 6.11696 4.33283 6.11696H7.73449C8.42549 6.11696 9.02685 5.6258 9.1823 4.9328L9.36044 4.13714C9.63729 3.05332 10.5484 2.3335 11.5911 2.3335H16.4088Z" fill="#FF5858" />
                                        </svg>

                                    </div>
                                </td>
                            </tr>
                            <tr class="bg-white">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                </th>
                                <td class="py-4 px-6 text-left">
                                    Exchange & Segment
                                </td>
                                <td class="py-4 px-6">
                                    <div className='flex  pb-2 '>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div class="flex items-center mr-4 mt-2">
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2" />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900"> View</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Add</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Edit</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Delete</label>
                                            </div>
                                        </div>
                                        <div className='w-1/5 lg:px-4 px-2'>
                                            <div className='flex items-center mt-2'>
                                                <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Print</label>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td class="py-4 px-6">
                                    <div className='lg:px-4 px-2'>
                                        <div className='flex items-center'>
                                            <input id="inline-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                            <label for="inline-checkbox" class="ml-2 text-sm font-medium text-gray-900">Export</label>
                                        </div>
                                    </div>
                                </td>
                                <td class="py-4 px-6">
                                    <div className='lg:px-4 px-2'>
                                        <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.0956 10.1468C22.3282 10.1468 22.5392 10.2483 22.706 10.4198C22.8615 10.603 22.9397 10.8305 22.9171 11.0708C22.9171 11.1501 22.2953 19.0134 21.9401 22.3232C21.7177 24.3543 20.4084 25.5875 18.4443 25.6213C16.9341 25.6552 15.4579 25.6668 14.0044 25.6668C12.4613 25.6668 10.9522 25.6552 9.48741 25.6213C7.58915 25.5758 6.27863 24.3205 6.06759 22.3232C5.70223 19.0017 5.09179 11.1501 5.08045 11.0708C5.0691 10.8305 5.14626 10.603 5.30284 10.4198C5.45715 10.2483 5.67954 10.1468 5.91328 10.1468H22.0956ZM16.4088 2.3335C17.4402 2.3335 18.3616 3.05332 18.6282 4.07998L18.8188 4.93164C18.9731 5.6258 19.5745 6.11696 20.2666 6.11696H23.6683C24.1222 6.11696 24.5 6.49379 24.5 6.97328V7.41661C24.5 7.88444 24.1222 8.27294 23.6683 8.27294H4.33283C3.87784 8.27294 3.5 7.88444 3.5 7.41661V6.97328C3.5 6.49379 3.87784 6.11696 4.33283 6.11696H7.73449C8.42549 6.11696 9.02685 5.6258 9.1823 4.9328L9.36044 4.13714C9.63729 3.05332 10.5484 2.3335 11.5911 2.3335H16.4088Z" fill="#FF5858" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="flex justify-center px-5 lg:px-12 py-4 mt-12">
                        <button
                            type="button"
                            class="text-[#0075FC] bg-[#ffffff] border-2 border-[#0075FC] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-2.5 mr-2 mb-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-2.5 mr-2 mb-2"
                        >
                            Submit
                        </button>
                    </div>
                </div>

            </div>

        </>
    )
}

export default ManagerPermissions