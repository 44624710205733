



import React from 'react';
import DataGrid, {
  Column, Selection, Summary, TotalItem,
} from 'devextreme-react/data-grid';
import { formatDate } from 'devextreme/localization';


const renderTitleHeader = (data)=>{
  return <p style={{ fontSize: '16px',fontWeight:'600' }} >{data.column.caption}</p>;
}

const textRender = (text)=>{
  return <p style={{ fontSize: '12px' }}>{text}</p>;
}
class ExchangeTbl extends React.Component {
  constructor(props) {
    super(props);
    this.orders = props.table_data
  }

  customizeDate(data) {
    return `First: ${formatDate(data.value, 'MMM dd, yyyy')}`;
  }

  render() {
    return (

      <React.Fragment>
         <div  className='' >

     <div className='flex justify-between mb-2 items-center' >

    <h4 className='text-[#323232] text-[16px] font-semibold'>
    EXCHANGE WISE ALERTS
    </h4>


 <button type="button" className="text-white bg-[#0075FC] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ml-auto z-10">View More</button>



     </div> </div>
        <DataGrid
          id="gridContainer"
          dataSource={this.orders}
          showBorders={true}>
          <Selection mode="single" />
          <Column dataField="exchange" caption="Exchange"  headerCellRender={renderTitleHeader} cellRender={(e)=>textRender(e.data.exchange)}/>
          <Column dataField="segment"  headerCellRender={renderTitleHeader} cellRender={(e)=>textRender(e.data.exchange)}/>
          <Column dataField="total_alert" caption="Total"  headerCellRender={renderTitleHeader}/>
          <Column dataField="closed_alert" caption="Closed"  headerCellRender={renderTitleHeader}/>
          <Column dataField="open_alert" caption="Open"  headerCellRender={renderTitleHeader}/>
          <Summary>
            <TotalItem
              column="Exchange"
              summaryType="count" />
            <TotalItem
              column="total_alert"
              summaryType="sum"
               />
            <TotalItem
              column="open_alert"
              summaryType="sum"/>
              
          
          </Summary>
        </DataGrid>
      </React.Fragment>
    );
  }
}

export default ExchangeTbl;


