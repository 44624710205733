import React, { useState, useEffect } from "react";
import ReportTable from "../reports/ReportTable";
import { GetCall, PostCall } from "../../service/Services";
import axiosInstance from "../../service/ApiCall";
import "./style.css";
import Usertable from "./Usertable";
import AddDocumentType from "../../components/AddDocumentType";
import axios from "axios";
import { BASE_API } from "../../helper/Api";
import { Spin, Tabs } from "antd";
import { Link, useLocation } from "react-router-dom";

export default function User() {
  const { state } = useLocation();
  const [isLoading, setIsLocation] = React.useState(false);
  const [reportsData, setReportsData] = React.useState([]);
  const [model, setmodel] = useState(false);
  const [dataFrom, setDateFrom] = React.useState("7-10-2022");
  const [dataTo, setDateTo] = React.useState("7-13-2022");
  const [alertType, setAlertType] = React.useState("BSE");
  const [clientID, setClientID] = React.useState("TCRT636");
  const [data, setData] = useState();
  const [blockData, setBlockData] = useState([]);
  const [unblockData, setUnblockData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      // Make a GET request to your API endpoint
      // const response = await axios.get(BASE_API + "api/user-list");

      const response = await axios.get(BASE_API + "api/user-list");
      console.log(response.data.data);

      const ar = [];
      for (let index = 0; index < response.data.data.length; index++) {
        const element = response.data.data[index];
        const obj = {
          // index: index + 1,
          ...element,
        };
        ar.push(obj);
      }

      let blockData = ar
        .filter((item) => item.isblocked === 1)
        .map((item, i) => ({ index: i + 1, ...item }));
      let unblockData = ar
        .filter((item) => item.isblocked === 0)
        .map((item, i) => ({ index: i + 1, ...item }));
      // setData(currentData);
      setBlockData(blockData);
      setUnblockData(unblockData);
      setLoading(false);
      // let currentData = ar
      //   .filter((item) => item.published === dataType)
      //   .map((item, i) => ({ index: i + 1, ...item }));
      setData(ar);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const items = [
    {
      key: "1",
      label: "Active User",
      children: (
        <Usertable
          dataType={0}
          data={unblockData}
          setData={setUnblockData}
          fetchData={() => fetchData()}
        />
      ),
    },
    {
      key: "2",
      label: "Block User",
      children: (
        <Usertable
          dataType={1}
          data={blockData}
          setData={setBlockData}
          fetchData={() => fetchData()}
        />
      ),
    },
  ];

  const handleTabChange = (data) => {
    console.log({ data });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin size="large" className="">
          <h1 className="mt-20 text-blue-900">Loading...</h1>
        </Spin>
      </div>
    );
  }

  // React.useEffect(() => {
  //   setIsLocation(true);
  //   fetchData();
  //   setIsLocation(false);
  // }, [dataFrom, dataTo, alertType, clientID]);

  return (
    <>
      {/* <div className="rounded-sm bg-white shadow-lg  p-2 justify-between flex items-center flex-wrap margin-style">
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            Date
          </label>
          <input
            name="start"
            type="date"
            className="input-size bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[120px]  p-2.5  drop-shadow-md"
            placeholder="07/22/2019"
            value={dataFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            State
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>AL</option>
            <option>AI</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            FirstName
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>Adam</option>
            <option>Mick</option>
          </select>
        </div>
        <div className="relative flex items-center ml-2 my-2 input-size">
          <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
            LastName
          </label>
          <select
            className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option>Thomas</option>
            <option>Luther</option>
          </select>
        </div>
            <div className="relative flex items-center ml-2 my-2 input-size">
            <label htmlFor="" className="text-[#0075FC] w-[70px] text-[12px]">
                import
            </label>
            <select
                className="input-size bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] p-2.5 drop-shadow-md"
                onChange={(e) => setAlertType(e.target.value)}
            >
                <option>BSE</option>
                <option>NSE</option>
            </select>
            </div>
        <div className="relative flex items-center ml-2 my-2 ">
          <button
            onClick={() => setmodel(true)}
            type="button"
            className="text-white w-[70px] bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 z-10"
          >
            GO
          </button>
        </div>
      </div> */}
      {/* <div className="">
        <Usertable reportData={reportsData} />
      </div> */}

      <div className="">
        <Tabs
          defaultActiveKey={state?.activeTab || "1"}
          items={items}
          onChange={(activeKey) => handleTabChange(activeKey)}
        />
        {/* <ObituaryTable reportData={reportsData} /> */}
      </div>
    </>
  );
}
